/* eslint-disable react/prop-types */
/* eslint-disable arrow-parens */
import { useState, useCallback, useRef, useContext } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { withStyles } from "@material-ui/core/styles";
import { Checkbox, CheckboxProps } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { GridApi } from "ag-grid-community";

import "./permissionManagementGrid.css";
import initCapitalize from "../../../../../../utils/commonFunctions/initCapitalize";
import { dateTimeZoneConverter } from "../../../../../shared/cellRenderer/cellRenderers";
import { AuthContext } from "../../../../../../contexts";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../../../../../utils/api";
import { GreenCheckbox } from "../../../../../shared/greenCheckBox/GreenCheckBox";

// import { CustomPagination } from "../../../../shared";

// eslint-disable-next-line react/prop-types
const PermissionManagementGrid = ({ gridProps, tabValue, columnDefs, handleChangeUserPermission }: any) => {
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	const { user, setUser } = useContext(AuthContext);
	const agGridRef = useRef<any>(null);
	const [agGridColumnAPI, setAgGridColumnAPI] = useState(null);
	const [selectedRowData, setSelectedRowData] = useState([]);
	const [isRowSelected, setIsRowSelected] = useState(false);
	const handleAgGridReady = (params: any) => {
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	console.log("gridProps", gridProps);

	// eslint-disable-next-line
	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};

	const handleUserRoleCellRenderer = ({ value }: { value: any }) => {
		console.log("role", value);
		const role = initCapitalize(value);
		return <div>{role}</div>;
	};

	const handleSSNCellRenderer = ({ value }: { value: string }) => {
		console.log("SSN", value);

		const SSN = value ? value.substring(0, 3) + "-" + value.substring(3, 5) + "-" + value.substring(5) : "N/A";
		return <div>{SSN}</div>;
	};

	const handleDateCellRenderer = ({ value }: { value: string }) => (value ? dateTimeZoneConverter(value) : "N/A");

	const handleSelectionChanged = useCallback(() => {
		// console.log("agGridAPI", gridApi);
		// const selectedRow = agGridAPI?.getSelectedRows();
		const selectedRow = agGridRef.current.api.getSelectedRows();
		console.log("Selected Row", selectedRow);
		setSelectedRowData(Object.assign([], selectedRow));
		if (selectedRow) {
			setIsRowSelected(true);
		} else {
			setIsRowSelected(false);
		}
	}, []);

	const handleScanDetailsCellRenderer = useCallback(
		({ data }: any) => {
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "feature_permission.is_scan_details",
					checked: !data.feature_permission.is_scan_details
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={data.feature_permission.is_scan_details}
					/>
				);
			}
		},
		[setUser, user]
	);

	const handleDiagnosisCellRenderer = useCallback(
		({ data }: any) => {
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "feature_permission.is_diagnosis_details",
					checked: !data.feature_permission.is_diagnosis_details
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={data.feature_permission.is_diagnosis_details}
					/>
				);
			}
		},
		[setUser, user]
	);

	const handleProductCellRenderer = useCallback(
		({ data }: any) => {
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "feature_permission.is_product_recommendation_details",
					checked: !data.feature_permission.is_product_recommendation_details
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={data.feature_permission.is_product_recommendation_details}
					/>
				);
			}
		},
		[setUser, user]
	);

	const handleShoeDetailsCellRenderer = useCallback(
		({ data }: any) => {
			const { component_permission } = data;
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "component_permission.scan_details.shoe_details_qr",
					checked: !component_permission.scan_details.shoe_details_qr
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={component_permission.scan_details.shoe_details_qr}
					/>
				);
			}
		},
		[setUser, user]
	);
	const handleFeetImagesCellRenderer = useCallback(
		({ data }: any) => {
			const { component_permission } = data;
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "component_permission.scan_details.feet_images",
					checked: !component_permission.scan_details.feet_images
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={component_permission.scan_details.feet_images}
					/>
				);
			}
		},
		[setUser, user]
	);
	const handleFeetAnalysisCellRenderer = useCallback(
		({ data }: any) => {
			const { component_permission } = data;
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "component_permission.scan_details.feet_image_analysis",
					checked: !component_permission.scan_details.feet_image_analysis
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={component_permission.scan_details.feet_image_analysis}
					/>
				);
			}
		},
		[setUser, user]
	);
	const handlePainPointsCellRenderer = useCallback(
		({ data }: any) => {
			const { component_permission } = data;
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "component_permission.diagnosis_details.painPointDisease",
					checked: !component_permission.diagnosis_details.painPointDisease
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={component_permission.diagnosis_details.painPointDisease}
					/>
				);
			}
		},
		[setUser, user]
	);
	const handleDiagnosisQuestionCellRenderer = useCallback(
		({ data }: any) => {
			const { component_permission } = data;
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "component_permission.diagnosis_details.diagnosisQuestion",
					checked: !component_permission.diagnosis_details.diagnosisQuestion
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={component_permission.diagnosis_details.diagnosisQuestion}
					/>
				);
			}
		},
		[setUser, user]
	);
	const handleProductRecommendationCellRenderer = useCallback(
		({ data }: any) => {
			const { component_permission } = data;
			if (user) {
				const payload = {
					group_owner_object_id: user._id,
					field: "component_permission.product_recommendation.product_recommendation",
					checked: !component_permission.product_recommendation.product_recommendation
				};
				const handleChange = async () => {
					handleChangeUserPermission(payload);
				};
				return (
					<GreenCheckbox
						// onClick={(event: any) => checkValueChangeClick(event, value.column.colId)}
						onChange={handleChange}
						checked={component_permission.product_recommendation.product_recommendation}
					/>
				);
			}
		},
		[setUser, user]
	);

	return (
		<div className="alert-subscription-grid-container glb-tab-grid-container">
			<div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
				<AgGridReact
					ref={agGridRef}
					rowData={gridProps}
					columnDefs={columnDefs}
					onGridReady={handleAgGridReady}
					onFirstDataRendered={handleFirstDataRendered}
					rowSelection={"multiple"}
					onSelectionChanged={handleSelectionChanged}
					animateRows
					frameworkComponents={{
						handleScanDetailsCellRenderer: handleScanDetailsCellRenderer,
						handleDiagnosisCellRenderer: handleDiagnosisCellRenderer,
						handleProductCellRenderer: handleProductCellRenderer,
						handleUserRoleCellRenderer: handleUserRoleCellRenderer,
						handleSSNCellRenderer: handleSSNCellRenderer,
						handleDateCellRenderer: handleDateCellRenderer,
						handleShoeDetailsCellRenderer: handleShoeDetailsCellRenderer,
						handleFeetImagesCellRenderer: handleFeetImagesCellRenderer,
						handleFeetAnalysisCellRenderer: handleFeetAnalysisCellRenderer,
						handlePainPointsCellRenderer: handlePainPointsCellRenderer,
						handleDiagnosisQuestionCellRenderer: handleDiagnosisQuestionCellRenderer,
						handleProductRecommendationCellRenderer: handleProductRecommendationCellRenderer
						// getCheckBoxValue: getCheckBoxValue
					}}
					suppressColumnVirtualisation={true}
					defaultColDef={{
						filter: "agTextColumnFilter",
						floatingFilter: true,
						resizable: true,
						filterParams: {
							suppressAndOrCondition: true
						}
					}}
					onCellValueChanged={(params) => {
						params.api.refreshCells({
							force: true
						});
					}}
				></AgGridReact>
			</div>
			{/* <div className="custom-pagination" id="custom-pagination">
				<CustomPagination pageCount={pageCount} currentPage={currentPage} handlePageChange={handlePageChange} />
			</div> */}
		</div>
	);
};

export default PermissionManagementGrid;
