import React, { useState } from "react";

const KnowMoreButton = ({ subject }: { subject: string }) => {
	const [knowMorePopupVisible, setKnowMorePopupVisible] = useState<boolean>(false);
	const handleOpenKnowMorePopup = () => {
		setKnowMorePopupVisible(true);
	};
	const handleCloseKnowMorePopup = () => {
		setKnowMorePopupVisible(false);
	};
	return (
		<div className="knowMore">
			<button onClick={handleOpenKnowMorePopup}>Know More</button>

			<div
				className="know-more-popup"
				style={{
					display: knowMorePopupVisible ? "block" : "none"
				}}
			>
				<div className="what-is-text"> {`What is ${subject}?`}</div>
				<div className="main-para">
					Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus, non, quos ipsum eius
					aspernatur facere totam ratione rerum reiciendis assumenda placeat earum deleniti nisi beatae
					corporis dolore repudiandae, quae sint?
				</div>
				<div className="second-text">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ut quidem totam aspernatur, explicabo
					nulla cupiditate veritatis blanditiis ipsa, eligendi animi quibusdam officia provident? Maxime
					recusandae quasi dolor cumque mollitia.
				</div>
				<div className="second-para">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore, mollitia nam dolorum accusamus
					itaque velit dignissimos eveniet autem at qui quasi sit quibusdam aperiam nobis quo sequi laborum?
					Asperiores, officia.
				</div>
				<div className="cross-button" onClick={handleCloseKnowMorePopup}>
					X
				</div>
			</div>
		</div>
	);
};

export default KnowMoreButton;
