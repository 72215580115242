import { ButtonProps } from "@material-ui/core";
import { FC } from "react";
import ThemeButton from "../theme/button/Button";
import "./actionButton.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActionButton: FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
	return (
		<div className="action-button-cont">
			<ThemeButton {...props} />
		</div>
	);
};

export default ActionButton;
