import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../contexts";
import { User } from "../../../../@types/user.types";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { PaginationQuery, PaginationTypes } from "../../../../@types/pagination.types";
import { api } from "../../../../utils/api";
import getAgGridHeight from "../../../../utils/commonFunctions/getAgGridHeight";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

const StoreAdminGrid = ({ groupNumber }: { groupNumber: number | null }) => {
	const { user } = useContext(AuthContext);
	const [stores, setStores] = useState<User[]>([]);
	const navigate = useNavigate();
	const [gridHeight, setGridHeight] = useState<string>("400px");

	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	// eslint-disable-next-line no-unused-vars
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);
	const [limit, setLimit] = useState<number | null>(null);

	console.log("====>group number", groupNumber);
	// const _limit = getLimit(xl, lg, md, sm, xs);
	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleViewcustomerList = ({ data }: { data: User }) => {
		const handleNavigateToCustomerList = () => {
			// navigate(`/store-admin/customers?gn=${data.group_number}&sn=${data.store_number}`);
		};
		return (
			<div>
				<Button className="ag-grid-cell-button" variant="outlined" onClick={handleNavigateToCustomerList}>
					See Details
				</Button>
			</div>
		);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};
	const getPaginatedStoreList = useCallback(async () => {
		try {
			if (groupNumber) {
				const filter: any = { group_number: groupNumber };
				const storeList = await api.groupOwner.getGroupOwnerList(filter);
				if (storeList && storeList) {
					setStores(storeList);
				}
			}
		} catch (error) {
			console.log("error", error);
		}
	}, [limit, groupNumber]);

	useEffect(() => {
		const _gridHeight = getAgGridHeight(stores.length);
		setGridHeight(_gridHeight);
	}, [stores.length]);

	const handleAddUserButtonClick = () => {
		const pathName = `/admin/add-user?gn=${groupNumber}`;
		navigate(pathName);
	};

	useEffect(() => {
		setLimit(5);
		getPaginatedStoreList();
	}, [getPaginatedStoreList]);

	return (
		<div>
			<div className="all-customer-page-container">
				<div className="pf-action-button-container" id="pf-action-button-container">
					<Button
						className="button-green"
						variant="contained"
						onClick={handleAddUserButtonClick}
						style={{
							cursor: "pointer",
							pointerEvents: "unset"
						}}
					>
						<span className="button-label-with-icon">Add User</span>
						<span>
							<GroupAddIcon className="button-icon" />
						</span>
					</Button>
				</div>
				<div className="all-customers-grid-container">
					<div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
						<AgGridReact
							ref={agGridRef}
							rowData={stores}
							onGridReady={handleAgGridReady}
							onFirstDataRendered={handleFirstDataRendered}
							rowSelection={"Single"}
							animateRows
							suppressColumnVirtualisation={true}
							defaultColDef={{
								filter: "agTextColumnFilter",
								floatingFilter: true,
								resizable: true
							}}
							enableCellChangeFlash={true}
							frameworkComponents={{
								handleViewcustomerList: handleViewcustomerList
								// handleActionDetailsButtonRender: handleActionDetailsButtonRender
							}}
						>
							<AgGridColumn
								field="first_name"
								headerName="First Name"
								flex={1}
								suppressSizeToFit
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
								floatingFilterComponent="customFloatingFilter"
								filter="customFilter"
								cellStyle={() => {
									return { cursor: "pointer", flex: 1 };
								}}
							></AgGridColumn>
							<AgGridColumn
								field="last_name"
								headerName="Last Name"
								flex={1}
								suppressSizeToFit
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
								floatingFilterComponent="customFloatingFilter"
								filter="customFilter"
								cellStyle={() => {
									return { cursor: "pointer", flex: 1 };
								}}
							></AgGridColumn>

							<AgGridColumn
								field="user_name"
								headerName="User Name"
								flex={1}
								suppressSizeToFit
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer", flex: 1 };
								}}
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
							></AgGridColumn>

							<AgGridColumn
								field="role"
								headerName="Role"
								flex={1}
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
							></AgGridColumn>
							<AgGridColumn
								field="email"
								headerName="Email"
								flex={1}
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
							></AgGridColumn>
							<AgGridColumn
								field="assign"
								flex={1}
								floatingFilter={false}
								cellRenderer="handleViewcustomerList"
								headerName="Assign"
							></AgGridColumn>
						</AgGridReact>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StoreAdminGrid;
