import { FC, useCallback, useEffect, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import axios from "axios";
import ActionButton from "../../shared/actionButton/ActionButton";
import { Divider, FormControl, Grid, InputLabel } from "@material-ui/core";
import ThemeTextField from "../../shared/theme/textField/ThemeTextField";

const labels = {
	group_name: "Group",
	store_id: "Store ID",
	group_id: "Group ID",
	store_name: "Store Name",
	city: "City",
	address_line_1: "Address Line 1",
	address_line_2: "Address Line 2",
	state_code: "State Code",
	pincode: "Pincode",
	contact_first_name: "Contact First Name",
	contact_last_name: "Contact First Last",
	contact_phone_number: "Contact Phone Number",
	district: "District",
	GST_number: "GST Number",
	PAN: "PAN"
};

const Store: FC = (): JSX.Element => {
	const [store, setStore] = useState<any | null>(null);
	const query = useQuery();

	const getStore = useCallback(async () => {
		try {
			if (query && query.get("sid")) {
				const store_id = query.get("sid");
				const response = await axios.get(
					`http://localhost:4008/api/v1/stores/get-all-store-for-specific?store_id=${store_id}`,
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("@jwt")}`
						}
					}
				);
				if (response.data.result[0]) {
					const _store = response.data.result[0];
					setStore(
						Object.assign(
							{},
							{
								group_id: _store.group.group_id,
								group_name: _store.group.group_name,
								..._store
							}
						)
					);
				}
			}
		} catch (err) {
			console.log("err");
			alert("Unable to add store");
		}
	}, [query]);

	useEffect(() => {
		getStore();
	}, [getStore]);

	return (
		<div className="store">
			<h1>Store</h1>
			{store &&
				Object.entries(store).map(([name, value], index) => {
					return (
						<div key={index}>
							{labels[name as keyof typeof labels] ? (
								<>
									<FormControl className="fullwidth" key={index}>
										<Grid container className="form-grid">
											<Grid item xl={2} lg={2} md={2} sm={6} xs={2} className="form-grid-label">
												<InputLabel
													shrink
													htmlFor="bootstrap-input"
													className="theme-input-label form-grid-label"
												>
													{labels[name as keyof typeof labels] + ":"}
												</InputLabel>
											</Grid>
											<Grid
												item
												xl={10}
												lg={10}
												md={10}
												sm={6}
												xs={6}
												className="form-grid-field"
											>
												<ThemeTextField
													name={name}
													value={value}
													onChange={() => null}
													style={{ width: 400 }}
												/>
											</Grid>
										</Grid>
									</FormControl>
									<div className="theme-section-margin" />
									{index === 1 ? (
										<>
											<Divider style={{ margin: "0rem -2rem" }} />
											<div className="theme-section-margin" />{" "}
										</>
									) : null}
								</>
							) : null}
						</div>
					);
				})}
			{/* <ActionButton onClick={handleAddStoreButtonClick}>
				<div>Add Store</div>
			</ActionButton> */}
		</div>
	);
};

export default Store;
