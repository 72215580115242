import { StatusCodes } from "http-status-codes";
import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";
import { IProduct } from "../../../@types/product.types";

const { post, get, patch, del, put } = request;
const { Authorization, Bearer } = AUTHORIZATION;

const initialRoute = "/products";
export const getPaginatedProductList = async (filterQuery: any) => {
	try {
		const endpoint = `${initialRoute}/get-products-by-page`;
		const token = localStorage.getItem("@jwt");
		const response = await get(
			endpoint,
			{
				...headers,
				[Authorization]: `${Bearer} ${token}`
			},
			filterQuery
		);
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};

export const addProduct = async (_payload: IProduct) => {
	try {
		const payload = JSON.stringify(_payload);
		const endpoint = `${initialRoute}/add-products`;
		const token = localStorage.getItem("@jwt");
		const response = await post(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;
			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;
			alert(message);
		} else {
			throw error;
		}
	}
};

export const getProductById = async (productId: string) => {
	try {
		const endpoint = `${initialRoute}/get-products-by-id?Id=${productId}`;
		const token = localStorage.getItem("@jwt");
		const response = await get(endpoint, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});

		if (response?.status === 200) {
			return response.data;
		} else {
			throw new Error(`Failed to fetch product with ID ${productId}`);
		}
	} catch (error) {
		console.error("Error fetching product details:", error);
		throw error;
	}
};

export const deleteProductById = async (productId: string) => {
	try {
		const endpoint = `${initialRoute}/delete-products-by-id?id=${productId}`;
		const token = localStorage.getItem("@jwt");
		const response = await del(endpoint, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});

		if (response?.status === 200) {
			return response.data;
		} else {
			throw new Error(`Failed to delete product with ID ${productId}`);
		}
	} catch (error) {
		console.error("Error deleting product:", error);
		throw error;
	}
};

export const editProductById = async (productId: any, _payload: Partial<IProduct>) => {
	try {
		const payload = JSON.stringify(_payload);
		const endpoint = `${initialRoute}/update-products-by-id?id=${productId}`;
		const token = localStorage.getItem("@jwt");
		const response = await put(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});

		if (response) {
			const {
				data: { message }
			} = response;

			if (message === MESSAGE.put.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}

		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;
			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;
			alert(message);
		} else {
			throw error;
		}
	}
};
