/* eslint-disable prettier/prettier */
import { Card, CardContent, Grid, InputAdornment, Paper, TextField } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";

import ReceiptIcon from "@material-ui/icons/Receipt";

import "./GridCard.css";
import { ILegScanProps } from "../../../../../../../@types/components/legScanDetailsprops.types";

const GridCard = ({ legScan }: ILegScanProps) => {
	return (
		<Paper elevation={3} style={{ height: "100%", padding: "1rem", backgroundColor: "#F8F8FF" }}>
			<h2 className="Profile-info-text">
				<span>
					<ReceiptIcon style={{ fontSize: "1.6rem" }} />
				</span>
				Scan Details
			</h2>
			<Grid container spacing={1} alignItems="center">
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Grid container className="form-label">
						<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
							<div className="label-text" id="label-text">
								<span>
									<ReplyIcon
										style={{
											fontSize: "1.5rem",
											paddingTop: 4
										}}
									/>
								</span>
								left shoe size:
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
							<div className="shoe-size label">
								<p>
									{legScan.left_shoe_size_uk} <span>(UK)</span>
								</p>
								<p>
									{legScan.left_shoe_size_eu} <span>(EU)</span>
								</p>
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
							<div className="label-text" id="label-text">
								<span>
									<ReplyIcon
										style={{
											fontSize: "1.5rem",
											paddingTop: 4
										}}
									/>
								</span>
								right shoe size:
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
							<div className="shoe-size label">
								<p>
									{legScan.right_shoe_size_uk} <span>(UK)</span>
								</p>
								<p>
									{legScan.right_shoe_size_eu} <span>(EU)</span>
								</p>
							</div>
						</Grid>
					</Grid>

					<Grid container spacing={1} className="form-label">
						<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
							<div className="label-text " id="label-text">
								<span>
									<ReplyIcon
										style={{
											fontSize: "1.5rem",
											paddingTop: 4
										}}
									/>
								</span>
								left width type:
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
							<p className="label" style={{ textAlign: "left" }}>
								{legScan.width_type_left}
							</p>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
							<div className="label-text " id="label-text">
								<span>
									<ReplyIcon
										style={{
											fontSize: "1.5rem",
											paddingTop: 4
										}}
									/>
								</span>
								right width type:
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
							<p className="label" style={{ textAlign: "left" }}>
								{legScan.width_type_right}
							</p>
						</Grid>
					</Grid>
					{/* <Grid container spacing={1} className="form-label">
										<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
											<div className="label-text" id="label-text">
												<span>
													<ReplyIcon
														style={{
															fontSize: "1.5rem",
															paddingTop: 4
														}}
													/>
												</span>
												left width:
											</div>
										</Grid>
										<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
											50
										</Grid>
										<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
											<div className="label-text" id="label-text">
												<span>
													<ReplyIcon
														style={{
															fontSize: "1.5rem",
															paddingTop: 4
														}}
													/>
												</span>
												right width:
											</div>
										</Grid>
										<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
											50
										</Grid>
									</Grid> */}
					{/* <Grid container spacing={1} className="form-label">
										<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
											<div className="label-text" id="label-text">
												<span>
													<ReplyIcon
														style={{
															fontSize: "1.5rem",
															paddingTop: 4
														}}
													/>
												</span>
												left length:
											</div>
										</Grid>
										<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
											80
										</Grid>
										<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
											<div className="label-text" id="label-text">
												<span>
													<ReplyIcon
														style={{
															fontSize: "1.5rem",
															paddingTop: 4
														}}
													/>
												</span>
												right length:
											</div>
										</Grid>
										<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
											80
										</Grid>
									</Grid> */}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default GridCard;
