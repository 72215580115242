import { lazy } from "react";

export { default as CustomFilter } from "./customFilter/CustomFilter.jsx";
export const LazyCustomFilter = lazy(() => import("./customFilter/CustomFilter.jsx"));

export { default as CustomFloatingFilter } from "./customFloatingFilter/CustomFloatingFilter.jsx";
export const LazyCustomFloatingFilter = lazy(() => import("./customFloatingFilter/CustomFloatingFilter.jsx"));

export { default as CustomPagination } from "./customPagination/CustomPagination";

export { default as CustomDialog } from "./dialogs/customDialog/CustomDialog";
export const LazyCustomDialog = lazy(() => import("./dialogs/customDialog/CustomDialog"));

export { default as CustomImageDialog } from "./dialogs/customImageDialog/CustomImageDialog";
export const LazyCustomImageDialog = lazy(() => import("./dialogs/customImageDialog/CustomImageDialog"));

export { default as CustomImageUploadDialog } from "./dialogs/customImageUploadDialog/CustomImageUploadDialog";
export const LazyCustomImageUploadDialog = lazy(
	() => import("./dialogs/customImageUploadDialog/CustomImageUploadDialog")
);

// export const LazyCustomImageDialogWithTitle = lazy(
// 	() => import("./dialogs/customImageDialogWithTitle/CustomImageDialogWithTitle")
// );

// export const LazyCustomPdfViewWithTitleDialog = lazy(
// 	() => import("./dialogs/customPdfViewWithTitleDialog/CustomPdfViewWithTitleDialog")
// );
