/* eslint-disable prettier/prettier */
/* eslint-disable arrow-parens */
import { useState, useCallback, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_DASHBOARD_HEADER } from "../../../../constants/caption/dashboardHeader";
import CustomPagination from "../../../shared/customPagination/CustomPagination1";
import { UIContext, AuthContext } from "../../../../contexts";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { Button, useMediaQuery } from "@material-ui/core";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { PaginationQuery, PaginationTypes } from "../../../../@types/pagination.types";
import getLimit from "../../../../utils/pagination/getLimit";
import ROLES from "../../../../constants/roles";
import initCapitalize from "../../../../utils/commonFunctions/initCapitalize";
// import { api } from "../../../../utils/api";
import useRouteParam from "../../../../hooks/useRouteParam";
// import { AssignedPaginatedcustomers } from "../../../../@types/api/admin/user/user.types";
// import { customer } from "../../../../@types/customer.types";
import { dateTimeZoneConverter } from "../../../../utils/commonFunctions/date";
import { handleValueCellRenderer } from "../../../../utils/commonFunctions/cellRenderers";
import { CustomFloatingFilter } from "../../../shared";
import { CustomFilter } from "../../../shared";
// import { UIFormatSSN } from "../../../../utils/commonFunctions/SSN";
// import { GROUP_NUMBER } from "../../../../constants/groups";

import "./groupCustomers.css";
import { ICustomerProps } from "../../../../@types/components/customerProps.types";
import { ICustomerType } from "../../../../@types/customer.types";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../../../utils/api";
import useQuerySearch from "../../../../hooks/querySearch/useQuerySearch";

const GroupCustomers = (): JSX.Element => {
	const { user } = useContext(AuthContext); // This is to extract the route after ?(Question Mark)
	const { setCustomerUI } = useContext(UIContext);
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);
	const { setDashboardHeader } = useContext(UIContext);
	const navigate = useNavigate();
	const [limit, setLimit] = useState<number | null>(null);
	const group_number = useRouteParam("gn");
	const store_number = useRouteParam("sn");
	const page = useQuerySearch("page");
	const [pagination, setPagination] = useState<PaginationTypes>({
		currentPage: Number(page) || 1,
		pageCount: undefined
	});
	const [customerList, setCustomerList] = useState<any[]>([]);
	const { currentPage, pageCount } = pagination;

	const [filterSchema, setFilterSchema] = useState<any>({
		first_name: { value: null, type: null },
		last_name: { value: null, type: null },
		phone: { value: null, type: null }
	});

	const xl = useMediaQuery("(min-width:1600px)");
	const lg = useMediaQuery("(min-width:1200px)");
	const md = useMediaQuery("(min-width:800px)");
	const sm = useMediaQuery("(min-width:600px)");
	const xs = useMediaQuery("(min-width:400px)");
	const _limit = getLimit(xl, lg, md, sm, xs);

	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};

	const handleViewcustomersDetails = () => {
		const focusedCell = agGridRef.current.api.getFocusedCell();
		const row = agGridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
		// console.log("Row", row);
		const { data } = row;
		console.log("data", data);
		setCustomerUI(data);
		navigate(`/admin/registered-customer-scan-details?gn=${data.group_number}&qs=${data._id}`);
	};

	const handleActionDetailsButtonRender = () => {
		return (
			<Button
				className="ag-grid-cell-button"
				variant="outlined"
				onClick={handleViewcustomersDetails}
				style={{ marginTop: "3px", marginBottom: "6px" }}
			>
				See Details
			</Button>
		);
	};

	// const handleActionHistoryButtonRender = useCallback(() => {
	// 	return (
	// 		<Button className="ag-grid-cell-button button-black" variant="outlined" onClick={handleNewPageChange}>
	// 			Move to History
	// 		</Button>
	// 	);
	// }, [handleNewPageChange]);

	const handlePageChange = useCallback(
		(event: React.ChangeEvent<unknown>, page: number) => {
			setPagination(
				Object.assign({}, pagination, {
					currentPage: page
				})
			);
			navigate(`/admin/customers?gn=${group_number}&page=${page}`);
		},
		[pagination]
	);

	const getPaginatedCustomerList = useCallback(async () => {
		if (typeof limit === "number" && typeof pagination.currentPage === "number") {
			let filterQuery: any = {};
			Object.keys(filterSchema).forEach((key) => {
				if (filterSchema[key].value !== null) {
					filterQuery[key] = { $regex: filterSchema[key].value, $options: "i" };
				}
			});
			const paginationQuery: PaginationQuery = {
				limit: limit,
				page: pagination.currentPage
			};
			if (Object.keys(filterQuery).length === 0) {
				if (store_number) {
					filterQuery = { ...paginationQuery, group_number: group_number, store_number };
				} else {
					filterQuery = { ...paginationQuery, group_number: group_number };
				}
			} else {
				if (store_number) {
					filterQuery = { ...filterQuery, group_number: group_number, store_number };
				} else {
					filterQuery = { ...filterQuery, group_number: group_number };
				}
			}
			console.log("=====>filter query", filterQuery);
			const customerListResponse = await trackPromise(api.customer.getPaginatedCustomerList({ ...filterQuery }));
			if (customerListResponse) {
				setCustomerList(customerListResponse.customers);
				setPagination(
					Object.assign({}, pagination, {
						pageCount: customerListResponse.totalPages
					})
				);
			}
		}
	}, [limit, pagination.currentPage, group_number, user, filterSchema]);

	useEffect(() => {
		if (_limit >= 0) {
			setLimit(_limit);
			getPaginatedCustomerList();
		}
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.customers);
	}, [_limit, getPaginatedCustomerList, setDashboardHeader]);

	useEffect(() => {
		if (page) {
			setPagination(
				Object.assign({}, pagination, {
					currentPage: Number(page)
				})
			);
		}
	}, [page]);

	return (
		<div className="all-customer-page-container">
			<div className="all-customers-grid-container">
				<div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
					<AgGridReact
						ref={agGridRef}
						rowData={customerList}
						onGridReady={handleAgGridReady}
						onFirstDataRendered={handleFirstDataRendered}
						rowSelection={"Single"}
						animateRows
						suppressColumnVirtualisation={true}
						defaultColDef={{
							filter: "agTextColumnFilter",
							floatingFilter: true,
							resizable: true
							//floatingFilterComponentParams: { suppressFilterButton: true }
						}}
						enableCellChangeFlash={true}
						frameworkComponents={{
							schema: filterSchema,
							setPayloadSchema: setFilterSchema,
							handleActionDetailsButtonRender: handleActionDetailsButtonRender,
							customFloatingFilter: CustomFloatingFilter,
							customFilter: CustomFilter
						}}
					>
						{user &&
						user.role === ROLES.super_admin &&
						((user.first_name === "Sourish" && user.last_name === "Mukherjee") ||
							(user.first_name === "Anupam" && user.last_name === "Biswas") ||
							(user.first_name === "Rahul" && user.last_name === "Dutta") ||
							(user.first_name === "Suman" && user.last_name === "Dutta")) ? (
							<AgGridColumn
								field="group_number"
								headerName="Group Number"
								floatingFilterComponentParams={{ suppressFilterButton: true }}
								// suppressSizeToFit
								filter={false}
							></AgGridColumn>
						) : null}
						<AgGridColumn
							field="customer_id"
							headerName="Customer Id"
							// suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { textAlign: "left", cursor: "pointer" };
							}}
						></AgGridColumn>
						{user?.is_customer_name_visibility ? (
							<>
								<AgGridColumn
									field="first_name"
									headerName="First Name"
									// suppressSizeToFit
									floatingFilterComponent="customFloatingFilter"
									filter="customFilter"
									onCellClicked={handleViewcustomersDetails}
									cellStyle={() => {
										return { textAlign: "left", cursor: "pointer" };
									}}
									//floatingFilterComponentParams={{ suppressFilterButton: true }}
									// floatingFilterComponentParams={{
									// 	suppressFilterButton: true
									// }}
								></AgGridColumn>
								<AgGridColumn
									field="last_name"
									headerName="Last name"
									// suppressSizeToFit
									//floatingFilterComponentParams={{ suppressFilterButton: true }}
									floatingFilterComponent="customFloatingFilter"
									filter="customFilter"
									onCellClicked={handleViewcustomersDetails}
									cellStyle={() => {
										return { textAlign: "left", cursor: "pointer" };
									}}
								></AgGridColumn>
							</>
						) : null}
						<AgGridColumn
							field="gender"
							headerName="Gender"
							suppressSizeToFit
							cellStyle={() => {
								return { textAlign: "left", cursor: "pointer" };
							}}
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn>
						{user?.is_phone_number_visibility ? (
							<AgGridColumn
								field="phone"
								headerName="Phone"
								floatingFilterComponent="customFloatingFilter"
								filter="customFilter"
								onCellClicked={handleViewcustomersDetails}
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
							></AgGridColumn>
						) : null}
						<AgGridColumn
							field="age_range"
							headerName="Age"
							cellStyle={() => {
								return { textAlign: "left", cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="action"
							headerName="action"
							filter={false}
							cellRenderer="handleActionDetailsButtonRender"
						></AgGridColumn>
					</AgGridReact>
				</div>
			</div>
			<div className="custom-pagination" id="custom-pagination">
				<CustomPagination pageCount={pageCount} currentPage={currentPage} handlePageChange={handlePageChange} />
			</div>
		</div>
	);
};

export default GroupCustomers;
