import React from "react";
import { footProfile, leftFeetProfile } from "../../../../../../../../assets";
import { Grid } from "@material-ui/core";
import KnowMoreButton from "../knowMoreButton/KnowMoreButton";

const LeftPainPointDetails = ({ disease, pain_point, disease_details, mode }: any) => {
	const painPointImage = pain_point
		? require(`../../../../../../../../assets/PainPoints/${pain_point}/left_foot_${pain_point}.png`)
		: null;

	return (
		<div className="leftPainPointDetails painpointDetailsDivision">
			<h2>Left Feet</h2>
			<Grid container spacing={1} alignItems="center">
				<Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
					<div className="footDisease">
						{mode !== "REPORT" ? <img src={footProfile} alt="foot" /> : null}
						<div className="disease-details">
							<h3>{disease}</h3>
							<p>{disease_details}</p>
							<KnowMoreButton subject="Hallux rigidus" />
						</div>
					</div>
				</Grid>
				<Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
					<img src={painPointImage} alt="left_feet_profile" />
					{mode !== "REPORT" ? (
						<div className="grid-customer-details">
							<Grid container style={{ padding: "5px 0" }}>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<h3>Disesase</h3>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<p>{disease}</p>
								</Grid>
							</Grid>
							<hr style={{ borderTop: "1px dashed #adacac" }}></hr>
							<Grid container style={{ padding: "5px 0" }}>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<h3>Pain Point</h3>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<p>{pain_point}</p>
								</Grid>
							</Grid>
							<hr style={{ borderTop: "1px dashed #adacac" }}></hr>
							<Grid container style={{ padding: "5px 0" }}>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<h3>Arch Type</h3>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<p>Flat Foot</p>
								</Grid>
							</Grid>
						</div>
					) : null}
				</Grid>
			</Grid>
		</div>
	);
};

export default LeftPainPointDetails;
