import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useEmployerRoute = (): boolean | null => {
	const [isEmployerRoute, setIsEmployerRoute] = useState<boolean | null>(null);
	const location = useLocation();

	useEffect(() => {
		if (location.pathname.split("/").includes("employer")) {
			setIsEmployerRoute(true);
		} else {
			setIsEmployerRoute(false);
		}
	}, [location.pathname]);

	return isEmployerRoute;
};

export default useEmployerRoute;
