import { createStyles, FormControl, makeStyles, NativeSelect, Theme } from "@material-ui/core";
import { ICustomerCount } from "../../../../../@types/interfaces/customerCount.interface";
import PieChart from "../../../../shared/charts/piechart/PieChart";
import "./CustomerDetails.css";
import useGetGroupList from "../../../../../utils/hooks/useGetGroupList/useGetGroupList";
import useQuerySearch from "../../../../../hooks/querySearch/useQuerySearch";
import { ChangeEvent } from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			minWidth: 120
		}
	})
);

const CustomerDetails = ({
	data,
	handleSelectGroup
}: {
	data: ICustomerCount;
	selectedGroup?: number;
	handleSelectGroup?: (event: ChangeEvent<HTMLSelectElement>) => void;
}) => {
	const classes = useStyles();
	const { groupList } = useGetGroupList();
	const groupNumber = useQuerySearch("gn");
	return (
		<div className="admin-pie-conatiner">
			{!groupNumber ? (
				<div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
					<div className="titleWithSelect">
						<FormControl className={classes.formControl}>
							<NativeSelect
								// value={state.age}
								onChange={handleSelectGroup}
								name="group_number"
								inputProps={{ "aria-label": "age" }}
							>
								{groupList.map((group, index) => (
									<option key={index} value={group.number}>
										{group.name}
									</option>
								))}
							</NativeSelect>
						</FormControl>
					</div>
				</div>
			) : null}

			<div className="admin-heading-title">
				<p>Total Customers Count</p>
				<h2>{data.totalCustomerCount}</h2>
			</div>
			<div className="admin-container-body">
				<div className="admin-left-data">
					<div className="admin-stat-data">
						<p>Registered Customer</p>
						<h2>{data.registeredCustomersCount}</h2>
					</div>
					<div className="admin-stat-data">
						<p>Unregistered Customer</p>
						<h2>{data.unRegisteredCustomersCount}</h2>
					</div>
				</div>
				<div className="admin-chart-container">
					<PieChart
						registeredCustomersCount={data.registeredCustomersCount}
						totalCustomerCount={data.totalCustomerCount}
						unRegisteredCustomersCount={data.unRegisteredCustomersCount}
						key="pie_customer_data"
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomerDetails;
