import React from "react";
import { Grid } from "@material-ui/core";
import { ILegScanProps } from "../../../../../../../@types/components/legScanDetailsprops.types";
import ImageContainer from "../ImageContainer/ImageContainer";

const FeetImages = ({ legScan }: ILegScanProps) => {
	return (
		<div className="imageAnalysis">
			<Grid container spacing={3} alignItems="center">
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<ImageContainer
						side="Left"
						backGroundColor="#eafaf8"
						raw_image_top={legScan.leftTopRawImage.image}
						raw_image_left={legScan.leftSideRawImage.image}
						predicted_image_left={legScan.leftSidePredictedImage.image}
						predicted_image_top={legScan.leftTopPredictedImage.image}
						key={0}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<ImageContainer
						side="Right"
						backGroundColor="#eafaf8"
						raw_image_top={legScan.rightTopRawImage.image}
						raw_image_left={legScan.righSideRawImage.image}
						predicted_image_left={legScan.rightSidePredictedImage.image}
						predicted_image_top={legScan.rightTopPredictedImage.image}
						key={1}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default FeetImages;
