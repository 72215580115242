import { Checkbox, CheckboxProps } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const GreenCheckbox = withStyles({
	root: {
		color: "#25a18e",
		"&$checked": {
			color: "#25a18e"
		}
	},
	onClick: {},
	checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} size="small" />);
