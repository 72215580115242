import { InputBase, InputBaseProps, Theme, alpha, withStyles } from "@material-ui/core";
import { FC } from "react";
import { THEME_COLOR } from "../../../../constants/theme";

const ThemeInput = withStyles((theme: Theme) => ({
	root: {
		"label + &": {
			marginTop: theme.spacing(3)
		}
	},
	input: {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.common.white,
		border: "1px solid #ced4da",
		fontSize: 16,
		width: "100%",
		background: "#F3F6F9",
		padding: "10px 12px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		// Use the system font instead of the default Roboto font.
		"&:focus": {
			boxShadow: `${alpha(THEME_COLOR.primaryColor, 0.25)} 0 0 0 0.2rem`,
			borderColor: theme.palette.primary.main
		}
	}
}))(InputBase);

const ThemeTextField: FC<InputBaseProps> = (props: InputBaseProps): JSX.Element => {
	return <ThemeInput {...props} />;
};

export default ThemeTextField;
