import { forwardRef, useState, ReactElement } from "react";
import { Button, Tooltip, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import downloadFile from "../../../../utils/commonFunctions/downloadFile";
import shortenFileName from "../../../../utils/commonFunctions/shortenFileName";
import { TransitionProps } from "@material-ui/core/transitions";
import { ComponentType } from "react";

import PublishIcon from "@material-ui/icons/Publish";

import "./customImageDialog.css";
import { CustomImageUploadDialogProps } from "../../../../@types/components/dialogProps.types";

const Transition: ComponentType<TransitionProps & { children?: ReactElement<any, any> | undefined }> = forwardRef(
	function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	}
);

const CustomImageUploadDialog = ({ dialogProps }: CustomImageUploadDialogProps) => {
	const { openDialog, title, uploadFields, actions, imageSrc } = dialogProps;
	return (
		<div>
			<Dialog
				style={{ zIndex: 5000 }}
				open={openDialog}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
				<DialogContent>
					<div className="upload-content">
						{uploadFields?.map((uploadField, index) => {
							const { buttonLabel, callback } = uploadField;
							return (
								<div key={index}>
									<input
										className={"logo-image-upload-" + index + ""}
										id={"logo-image-upload-" + index + ""}
										name={buttonLabel}
										type="file"
										onChange={callback}
										style={{ display: "none" }}
										accept="image/png, image/jpeg"
									/>
									<label htmlFor={"logo-image-upload-" + index + ""}>
										<Button className="button-green" component="span" variant="contained">
											<span className="button-label-with-icon">{buttonLabel}</span>
											<span>
												<PublishIcon className="button-icon" style={{ color: "#ffff" }} />
											</span>
										</Button>
									</label>
								</div>
							);
						})}
						{imageSrc ? (
							<Tooltip title={"Click to download"} arrow>
								<Chip
									label={
										imageSrc && imageSrc.originalname
											? shortenFileName(imageSrc.originalname)
											: imageSrc.name
											? shortenFileName(imageSrc.name)
											: "No files added"
									}
									onClick={() => downloadFile(imageSrc)}
								/>
							</Tooltip>
						) : null}
					</div>
				</DialogContent>
				<DialogActions>
					{actions?.map((action, index) => {
						const { label, callback } = action;

						return (
							<Button
								className="action-button"
								variant="contained"
								key={index}
								onClick={callback}
								// console.log("values", values);
								// callback(values);
								// setValues("");

								style={{
									backgroundColor: "#3a4652", //"#44b700" --> Green Color
									letterSpacing: "1px",
									fontSize: "0.7rem",
									fontWeight: "bold",
									color: "#ffff",
									marginRight: 10,
									marginLeft: 5
								}}
							>
								{label}
							</Button>
						);
					})}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CustomImageUploadDialog;
