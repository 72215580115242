import React, { useCallback, useEffect, useState } from "react";
import CustomerScanDetails from "../customerDetails/customerScanDetails/CustomerScanDetails";
import FourBox from "./fourBox/FourBox";
import { CompanySampleLogo, DecathlonLogoBgremove, Logo } from "../../../../../assets";
import PainPointDetails from "../customerDetails/customerDiagonisisDetails/painPointDetails/PainPointDetails";
import { IDiagnosis } from "../../../../../@types/diagnosis.interface";
import { api } from "../../../../../utils/api";
import useQuerySearch from "../../../../../hooks/querySearch/useQuerySearch";

const Reports = () => {
	const [diagnosisDetails, setDiagnosisDetails] = useState<IDiagnosis | null>(null);
	const scanObejctId = useQuerySearch("qs");
	const getQuestionnaire = useCallback(async () => {
		const filter = {
			customer_scan_object_id: scanObejctId
		};
		const response = await api.diagnosis.getDiagnosis(filter);
		// console.log("response", response);
		setDiagnosisDetails(response[0]);
		console.log("response", response[0]);
	}, [scanObejctId]);

	useEffect(() => {
		getQuestionnaire();
	}, [getQuestionnaire]);
	return (
		<div className="report" style={{ padding: "20px" }}>
			<div className="green-border">
				<div
					className="inline-flex"
					style={{ justifyContent: "center", marginBottom: "5px", marginTop: "-10px" }}
				>
					{/* <img src={DecathlonLogoBgremove} style={{ width: 200 }} /> */}
					<img src={Logo} style={{ width: 200 }} />
				</div>
				<CustomerScanDetails state="REPORT" key={0} />
				<div style={{ marginTop: 700 }}>
					{/* <FourBox /> */}
					<PainPointDetails
						left_disease={diagnosisDetails?.left_feet_diagnosis_name}
						right_disease={diagnosisDetails?.right_feet_diagnosis_name}
						left_feet_pain_point={diagnosisDetails?.left_feet_pain_point}
						right_feet_pain_point={diagnosisDetails?.right_feet_pain_point}
						left_diease_details={diagnosisDetails?.left_feet_diesease_details.disease_description}
						right_diease_details={diagnosisDetails?.right_feet_diesease_details.disease_description}
						mode="REPORT"
					/>
					<div className="inline-flex" style={{ justifyContent: "center", marginTop: "5px" }}>
						<div className="powered-by-container">Powered by: </div>
						<img src={Logo} style={{ width: 200 }} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reports;
