import React, { useContext, useEffect } from "react";
import StoreAdminGrid from "./storeAdminGrid/StoreAdminGrid";
import useRouteParam from "../../../hooks/useRouteParam";
import { UIContext } from "../../../contexts";

const StoreAdmin = () => {
	const groupNumber = useRouteParam("gn");
	const { setDashboardHeader } = useContext(UIContext);

	useEffect(() => {
		setDashboardHeader("Group Users");
	}, [setDashboardHeader]);
	return (
		<div>
			<StoreAdminGrid groupNumber={Number(groupNumber)} />
		</div>
	);
};

export default StoreAdmin;
