import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { fi } from "date-fns/locale";
import { useState } from "react";
import useQuerySearch from "../../../hooks/querySearch/useQuerySearch";

const DownloadReport = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const qs = useQuerySearch("qs");
	const printPageLink = () => {
		setLoading(true);
		const pageLink = `https://admin.curafoot.ai/reports?qs=${qs}`;
		const iframe = document.createElement("iframe");
		iframe.style.display = "none";
		iframe.src = pageLink;
		iframe.onload = () => {
			setTimeout(() => {
				setLoading(false);
				iframe.contentWindow?.print();
			}, 8000); // Wait for 3 seconds to load the page properly
		};
		document.body.appendChild(iframe);
	};

	return (
		<div>
			{/* <button onClick={printPageLink}>Print Page Link</button> */}
			<Button onClick={printPageLink} variant="contained" className="dark-button" endIcon={<GetAppIcon />}>
				{loading ? "Generating..." : "Generate Report"}
			</Button>
		</div>
	);
};

export default DownloadReport;
