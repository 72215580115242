/* eslint-disable arrow-parens */
const getAgGridHeight = (length) => {
	switch (length) {
		case 0:
			return "300px";
		case 1:
			return "300px";
		case 2:
			return "300px";
		case 3:
			return "340px";
		case 4:
			return "390px";
		case 5:
			return "440px";
		case 6:
			return "590px";
		default:
			return "440px";
	}
};

export default getAgGridHeight;
