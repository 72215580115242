import { addGroupOwners } from "./auth/addGroupOwnerDetials";
import { login } from "./auth/login";
import { getPaginatedCustomerList } from "./customer/getCustomer";
import { getDashboardData } from "./dashboard/dashboard.api";
import { deviceManagement } from "./deviceManagement/deviceManagement";
import { addDisease, getAllDiseaseList, getDiseaseDetails, updateDiseaseDetails } from "./disease/getDisease.api";
import {
	addGroup,
	getAllAssignGroupStore,
	getAllStoresByGroup,
	getGroupDetails,
	getPaginatedGroupList
} from "./group/group.api";
import { getGroupOwnerDetails, getGroupOwnerList } from "./groupOwner/getGroupOwnerDetails";
import { deleteNote, getNotes, postNotes, updateNote } from "./notes/notes.api";
import {
	getPaginatedGroupOwnerList,
	postAssignGroup,
	postAssignStore,
	updateGroupOwnersPermission
} from "./permission/permissionManagement";
import {
	addProduct,
	deleteProductById,
	editProductById,
	getPaginatedProductList,
	getProductById
} from "./product/product.management";
import { getDiagnosis } from "./questionnire/questionnaire.api";
import { getRecommendation } from "./recommendation/recommendation.api";
import { getPaginatedScanList } from "./scan/getScan";
import { getScanDetailsByScanObjectId } from "./scanDetails/getScanDetails";
import { addStore, getStoreList } from "./stores/store.management";

export const api = {
	auth: {
		addGroupOwners: addGroupOwners,
		login: login
	},
	deviceManagement: {
		addDevice: deviceManagement.addDevice,
		getDevice: deviceManagement.getDevice,
		updateDevice: deviceManagement.updateDevice
	},
	group: {
		getPaginatedGroupList: getPaginatedGroupList,
		getAllStoresByGroup: getAllStoresByGroup,
		getAllAssignGroupStore: getAllAssignGroupStore,
		addGroup: addGroup,
		getGroupDetails
	},
	groupOwner: {
		getGroupOwnerDetails: getGroupOwnerDetails,
		getGroupOwnerList: getGroupOwnerList
	},
	customer: {
		getPaginatedCustomerList: getPaginatedCustomerList,
		getPaginatedScanList: getPaginatedScanList
	},
	scan: {
		getScanDetailsByScanObjectId: getScanDetailsByScanObjectId
	},
	product: {
		getPaginatedProductList: getPaginatedProductList,
		addProduct: addProduct,
		editProductById: editProductById,
		deleteProductById: deleteProductById,
		getProductById: getProductById
	},
	disease: {
		getAllDiseaseList: getAllDiseaseList,
		addDisease: addDisease,
		getDiseaseDetails,
		updateDiseaseDetails
	},
	userManagement: {
		getPaginatedGroupOwnerList: getPaginatedGroupOwnerList,
		updateGroupOwnersPermission: updateGroupOwnersPermission
	},
	permissionManagement: {
		getPaginatedGroupOwnerList: getPaginatedGroupOwnerList,
		updateGroupOwnersPermission: updateGroupOwnersPermission,
		postAssignGroup: postAssignGroup,
		postAssignStore: postAssignStore
	},
	diagnosis: {
		getDiagnosis: getDiagnosis
	},
	stores: {
		getStoreList: getStoreList,
		addStore
	},
	recommendation: {
		getRecommendation: getRecommendation
	},
	notes: {
		getNotes: getNotes,
		postNotes: postNotes,
		updateNote: updateNote,
		deleteNote: deleteNote
	},
	dashboard: {
		getDashboardData
	}
};
