export const ADMIN_DASHBOARD_HEADER = {
	summary: "Summary",
	dashBoard: "Dashboard",
	user_management: "User Managment",
	devices: "Devices",
	add_device: "Add Device",
	groups: "Groups",
	products: "Products",
	all_customers: "All Customers",
	customers: "Customers",
	registered_customer_scan_details: "Registered Customer Scan Details",
	customer_details: "Customer Details",
	product_managment: "Product Managment",
	disease_managment: "Disease Managment",
	scan_details: "Scan Details",
	limit_groups: "Assign Groups and Stores",
	permission_management: "Permission Management",
	stores: "Branches",
	send_text: "Send Text Message"
};
