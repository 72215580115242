import { StatusCodes } from "http-status-codes";
import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";

const { post, get, patch, del, put } = request;
const { Authorization, Bearer } = AUTHORIZATION;

const initialRoute = "/stores";
export const getStoreList = async (filterQuery: any) => {
	try {
		const endpoint = `${initialRoute}/get-store-by-page`;
		const token = localStorage.getItem("@jwt");
		const response = await get(
			endpoint,
			{
				...headers,
				[Authorization]: `${Bearer} ${token}`
			},
			filterQuery
		);
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		throw error;
	}
};

export const addStore = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/add-store`;
		const token = localStorage.getItem("@jwt");
		const response = await post(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};
