import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";
import { User } from "../../../@types/user.types";

const { get, patch, post } = request;
const { Authorization, Bearer } = AUTHORIZATION;

const initialRoute = "permission-management";

export const getPaginatedGroupOwnerList = async (filterQuery: any) => {
	try {
		const endpoint = `${initialRoute}/get-group-owners-page`;
		const token = localStorage.getItem("@jwt");
		const response = await get(
			endpoint,
			{
				...headers,
				[Authorization]: `${Bearer} ${token}`
			},
			filterQuery
		);
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};

export const updateGroupOwnersPermission = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/update-group-owners`;
		const token = localStorage.getItem("@jwt");
		const response = await patch(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.patch.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};

export const updateFeatureComponentPermission = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/update-feature-component-permission`;
		const token = localStorage.getItem("@jwt");
		const response = await patch(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.patch.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};
export const postAssignGroup = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/assign-group`;
		const token = localStorage.getItem("@jwt");
		const response = await post(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};
export const postAssignStore = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/assign-store`;
		const token = localStorage.getItem("@jwt");
		const response = await post(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};
