import "./DataCard.css";

const DataCard = ({ arc_type }: { arc_type: string }) => {
	return (
		<div className="dataCard">
			<div className="title">Arch Type</div>
			<div className="value ">
				<p>
					<span className="label-number-item">{arc_type}</span>
				</p>
			</div>
		</div>
	);
};

export default DataCard;
