import { Grid } from "@material-ui/core";
import GridCard from "../gridCard/GridCard";
import ReportQrCode from "../reportQrCode/ReportQrCode";
import { ILegScanProps } from "../../../../../../../@types/components/legScanDetailsprops.types";

const ScanDetailsWithQrCode = ({ legScan, state }: ILegScanProps) => {
	return (
		<div>
			<Grid container justifyContent="center" spacing={3}>
				<Grid
					item
					xs={12}
					sm={12}
					md={state === "REPORT" ? 12 : 10}
					lg={state === "REPORT" ? 12 : 10}
					xl={state === "REPORT" ? 12 : 10}
				>
					<GridCard legScan={legScan} state={state} />
				</Grid>
				{state !== "REPORT" ? (
					<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
						<ReportQrCode scan_id={legScan._id || ""} />
					</Grid>
				) : null}
			</Grid>
		</div>
	);
};

export default ScanDetailsWithQrCode;
