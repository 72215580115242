import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";
import { Params } from "react-router-dom";

const { get, post, patch, del } = request;
const { Authorization, Bearer } = AUTHORIZATION;

const initialRoute = "notes";

export const getNotes = async (filterQuery: any) => {
	try {
		const endpoint = `${initialRoute}/get-notes`;
		const token = localStorage.getItem("@jwt");
		const response = await get(
			endpoint,
			{
				...headers,
				[Authorization]: `${Bearer} ${token}`
			},
			filterQuery
		);
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};

export const postNotes = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/post-notes`;
		const token = localStorage.getItem("@jwt");
		const response = await post(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		alert(error);
		throw error;
	}
};

export const updateNote = async (_payload: any) => {
	try {
		const endpoint = `${initialRoute}/update-note`;
		const token = localStorage.getItem("@jwt");
		const response = await patch(endpoint, _payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.patch.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log("===>error this api", error);
	}
};
export const deleteNote = async (noteObjId: any) => {
	try {
		const endpoint = `${initialRoute}/delete-note/${noteObjId}`;
		const token = localStorage.getItem("@jwt");
		const response = await del(endpoint, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.delete.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log("===>error this api", error);
	}
};
