import React, { useCallback, useEffect, useState } from "react";
import { Group } from "../../../@types/group.types";
import { api } from "../../api";

const useGetGroupList = () => {
	const [groupList, setGroupList] = useState<{ name: string; number: number }[]>([]);

	const getGroupList = useCallback(async () => {
		const response = await api.group.getPaginatedGroupList({});
		const groupList: Group[] = response.groups;
		const groupListData = groupList.map((group: Group) => {
			return { name: group.group_name, number: group.group_number };
		});
		setGroupList(groupListData);
	}, []);

	useEffect(() => {
		getGroupList();
	}, [getGroupList]);
	return { groupList };
};

export default useGetGroupList;
