/* eslint-disable prettier/prettier */
import { Grid } from "@material-ui/core";

import "./groupProfile.css";
import { ReactNode } from "react";

const GroupProfile = ({ details, header }: any) => {
	const handleUnerScore = (key: string) => {
		return key.replace(/_/g, " ");
	};
	return (
		<div className="group-basic-details">
			<div className="header">{header}</div>
			{details
				? Object.entries(details).map(([key, value], index) => {
						return (
							<Grid container key={index} className="form-field">
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<div className="detail-label">{handleUnerScore(key)}</div>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									{value as ReactNode}
								</Grid>
							</Grid>
						);
				  })
				: null}
		</div>
	);
};

export default GroupProfile;
