import { FC, useCallback, useState } from "react";
import { Logo } from "../../../../assets";
import { FormControl, InputLabel, Paper } from "@material-ui/core";
import ThemeTextField from "../../../shared/theme/textField/ThemeTextField";
import ThemeButton from "../../../shared/theme/button/Button";
import { AiOutlineLogin } from "react-icons/ai";
import axios from "axios";

const ForgetPassword: FC = (): JSX.Element => {
	const [userId, setUserID] = useState("");

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserID(event.target.value);
	};

	const handleSubmit = useCallback(async () => {
		try {
			const response = await axios.post("http://localhost:4008/api/v1/auth/forget-password/get-token", {
				role: "ADMIN",
				credential: userId
			});
			if (response) {
				alert("Reset Password link has been sent to your registered Email ID");
			}
		} catch (err) {
			alert("Sorry! No such user found");
		}
	}, [userId]);

	return (
		<div className="forget-password auth">
			<div className="auth-container">
				<div className="logo-container">
					<img src={Logo} className="auth-img" />
				</div>
				<Paper elevation={3} className="auth-paper-container">
					<div className="auth-text theme-primary-color">Forget Password?</div>
					<div className="theme-section-margin" />
					<FormControl className="fullwidth">
						<InputLabel shrink htmlFor="bootstrap-input" className="theme-input-label">
							Enter your Username/Email
						</InputLabel>
						<ThemeTextField value={userId} onChange={handleChange} />
					</FormControl>
					<div className="theme-section-margin" />
					<ThemeButton onClick={handleSubmit}>
						Submit{" "}
						<span className="button-icon">
							<AiOutlineLogin />
						</span>
					</ThemeButton>
				</Paper>
			</div>
		</div>
	);
};

export default ForgetPassword;
