import React, { useCallback, useContext, useEffect, useState } from "react";
import DiseaseManagementGrid from "./diseaseManagementGrid/DiseaseManagementGrid";
import { api } from "../../../../../utils/api";
import { TDisease } from "../../../../../@types/disease.types";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { UIContext } from "../../../../../contexts";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../constants/caption/dashboardHeader";

const DiseaseManagement = () => {
	const [disesaseList, setDiseaseList] = useState<any[]>([]);
	const { setDashboardHeader } = useContext(UIContext);
	const navigate = useNavigate();
	const getAllDevices = async () => {
		const response = await api.disease.getAllDiseaseList();
		if (response) {
			setDiseaseList(response);
		}
	};

	const handleAddDiseaseClick = () => {
		const path = "/settings/add-disease";
		navigate(path);
	};
	useEffect(() => {
		getAllDevices();
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.disease_managment);
	}, []);
	return (
		<div>
			<div className="pf-action-button-container" id="pf-action-button-container">
				<Button
					className="button-green"
					variant="contained"
					onClick={handleAddDiseaseClick}
					style={{
						cursor: "pointer",
						pointerEvents: "unset"
					}}
				>
					<span className="button-label-with-icon">Add Disease</span>
					<span>{/* <DevicesIcon className="button-icon" /> */}</span>
				</Button>
			</div>
			<DiseaseManagementGrid gridData={disesaseList} key={1} />
		</div>
	);
};

export default DiseaseManagement;
