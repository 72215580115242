import { Grid } from "@material-ui/core";
import { bataLogo } from "../../../../../assets";
import { Suspense, useEffect, useState } from "react";

import GroupWorkIcon from "@material-ui/icons/GroupWork";
import GroupIcon from "@material-ui/icons/Group";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import GroupProfile from "./groupProfile/GroupProfile";

import "./groupInformation.css";

const GroupInformation = ({ groupInformation }: any) => {
	console.log("groupInformation", groupInformation);
	const [groupBasicDetails, setGroupBasicDetails] = useState<any>();
	const handleGroupBasicDetails = () => {
		if (groupInformation) {
			setGroupBasicDetails(
				Object.assign(
					{},
					{
						Name: groupInformation && groupInformation.group_name,
						GST: groupInformation && groupInformation.GST_number,
						Group_Owner_Name:
							(groupInformation && groupInformation.contact_first_name) +
							" " +
							(groupInformation && groupInformation.contact_last_name),
						PAN: groupInformation && groupInformation.PAN,
						NO_Of_Total_Stores:
							(groupInformation && groupInformation.assigned_stores.length) +
							(groupInformation && groupInformation.unassigned_stores.length)
					}
				)
			);
		}
	};
	useEffect(() => {
		handleGroupBasicDetails();
	}, []);
	return (
		<div>
			<div className="group-information">
				<Grid container>
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
						<div className="left-container">
							<img src={bataLogo} alt="logo" />
							<h2 className="group-name">{groupInformation && groupInformation.group_name}</h2>
						</div>
					</Grid>

					<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
						<div className="information">
							<div className="special-details">
								<div className="special-detail">
									<GroupWorkIcon />
									<div className="field">
										<span className="label">Master Number:</span>
										<span className="value">&nbsp; 202-210</span>
									</div>
								</div>
								<div className="special-detail">
									<GroupIcon />
									<div className="field">
										<span className="label">Group Number:</span>
										<span className="value">
											&nbsp; &nbsp;{groupInformation && groupInformation.group_number}
										</span>
									</div>
								</div>
								<div className="special-detail">
									<EmailIcon />
									<div className="field">
										<span className="label">Email:</span>
										<span className="value">
											&nbsp; {groupInformation && groupInformation.email}
										</span>
									</div>
								</div>
								<div className="special-detail">
									<PhoneIcon />
									<div className="field">
										<span className="label">Phone:</span>
										<span className="value">
											&nbsp; {groupInformation && groupInformation.phone}
										</span>
									</div>
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
				<hr></hr>

				<div className="details">
					<GroupProfile details={groupBasicDetails} header={"Basic Details"} />
				</div>
			</div>
		</div>
	);
};

export default GroupInformation;
