/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useEffect, useState } from "react";
import PermissionManagementGrid from "./permissionManagementGrid/PermissionManagementGrid";
import { Box, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import a11yProps from "../../../../../constants/tabPanelProps/ayProps";
import TabPanel from "../../../../shared/tabPanelComponent/TabPanel";
import GroupOwnersGrid from "./groupOwnersGrid/GroupOwnersGrid";
import { AuthContext, UIContext } from "../../../../../contexts";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../constants/caption/dashboardHeader";
import { User } from "../../../../../@types/user.types";
import { trackPromise } from "react-promise-tracker";
import { PaginationQuery, PaginationTypes } from "../../../../../@types/pagination.types";
import { api } from "../../../../../utils/api";
import getLimit from "../../../../../utils/pagination/getLimit";

const PermissionManagement = () => {
	const { user, setUser } = useContext(AuthContext);
	const [value, setValue] = useState<number>(0);
	const { setDashboardHeader } = useContext(UIContext);
	const [groupOwners, setGroupOwners] = useState<User[]>([]);
	const [limit, setLimit] = useState<number | null>(null);
	const [pagination, setPagination] = useState<PaginationTypes>({
		currentPage: 1,
		pageCount: undefined
	});
	const { currentPage, pageCount } = pagination;

	const xl = useMediaQuery("(min-width:1600px)");
	const lg = useMediaQuery("(min-width:1200px)");
	const md = useMediaQuery("(min-width:800px)");
	const sm = useMediaQuery("(min-width:600px)");
	const xs = useMediaQuery("(min-width:400px)");
	const _limit = getLimit(xl, lg, md, sm, xs);

	const columnFeatureDefs = [
		{
			field: "first_name",
			headerName: "First Name",
			floatingFilterComponentParams: { suppressFilterButton: true },
			pinned: "left"
		},
		{
			field: "last_name",
			headerName: "Last Name",
			floatingFilterComponentParams: { suppressFilterButton: true },
			pinned: "left"
		},
		{
			field: "role",
			headerName: "Role",
			pinned: "left",
			floatingFilterComponentParams: { suppressFilterButton: true },
			cellRenderer: "handleUserRoleCellRenderer"
		},
		{
			field: "feature_permission",
			headerName: "Scan Details",
			filter: false,
			cellRenderer: "handleScanDetailsCellRenderer"
		},
		{
			field: "feature_permission",
			headerName: "Diagnosis Questionnaire",
			filter: false,
			cellRenderer: "handleDiagnosisCellRenderer"
		},
		{
			field: "feature_permission",
			headerName: "Product Recommendation",
			filter: false,
			cellRenderer: "handleProductCellRenderer"
		}
	];

	const columnComponentDefs = [
		{
			headerName: "Basic Details",
			children: [
				{
					field: "first_name",
					headerName: "First Name",
					suppressSizeToFit: true,
					floatingFilterComponentParams: { suppressFilterButton: true },
					pinned: "left"
				},
				{
					field: "last_name",
					headerName: "Last Name",
					suppressSizeToFit: true,
					floatingFilterComponentParams: { suppressFilterButton: true },
					pinned: "left"
				},
				{
					field: "role",
					headerName: "Role",
					pinned: "left",
					suppressSizeToFit: true,
					floatingFilterComponentParams: { suppressFilterButton: true },
					cellRenderer: "handleUserRoleCellRenderer"
				}
			]
		},
		{
			headerName: "Scan Details",
			children: [
				{
					field: "component_details",
					headerName: "Shoe Size",
					cellRenderer: "handleShoeDetailsCellRenderer",
					filter: false
				},
				{
					field: "component_details",
					headerName: "Feet Images",
					cellRenderer: "handleFeetImagesCellRenderer",
					filter: false
				},
				{
					field: "component_details",
					headerName: "Feet Image Analysis",
					cellRenderer: "handleFeetAnalysisCellRenderer",
					filter: false
				}
			]
		},
		{
			headerName: "Diagnosis Details",
			children: [
				{
					field: "component_details",
					headerName: "Pain Points and Disease",
					cellRenderer: "handlePainPointsCellRenderer",
					filter: false
				},
				{
					field: "component_details",
					headerName: "Diagnosis Questionnaire",
					cellRenderer: "handleDiagnosisQuestionCellRenderer",
					filter: false
				}
			]
		},
		{
			headerName: "Product Recommendation",
			children: [
				{
					field: "component_details",
					headerName: "Product Recommendations",
					cellRenderer: "handleProductRecommendationCellRenderer",
					filter: false
				}
			]
		}
	];
	const handleLabelChange = (event: React.ChangeEvent<unknown>, newValue: any) => {
		setValue(newValue as number);
	};

	const getPaginatedGroupOwnerList = useCallback(async () => {
		if (typeof limit === "number" && typeof pagination.currentPage === "number") {
			const paginationQuery: PaginationQuery = {
				limit: limit,
				page: pagination.currentPage,
				role: user?.role
			};
			const groupList = await trackPromise(api.permissionManagement.getPaginatedGroupOwnerList(paginationQuery));
			setGroupOwners(groupList.group_owners);
		}
	}, [limit, pagination.currentPage]);

	const handleChangeUserPermission = useCallback(
		async (payload: any) => {
			const response = await trackPromise(api.userManagement.updateGroupOwnersPermission(payload));
			if (response) {
				if (response._id === user?._id) {
					setUser(response);
				}
			}
		},
		[setUser, user?._id]
	);

	useEffect(() => {
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.permission_management);
	}, [setDashboardHeader]);

	useEffect(() => {
		if (_limit >= 0) {
			setLimit(_limit);
			getPaginatedGroupOwnerList();
		}
	}, [_limit, getPaginatedGroupOwnerList, user]);

	return (
		<div>
			<div className="alert-subscription-container">
				<Box sx={{ width: "100%" }}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs
							value={value}
							onChange={handleLabelChange}
							aria-label="basic tabs example"
							TabIndicatorProps={{
								style: {
									border: "none",
									backgroundColor: "#558b2f"
								}
							}}
							className="glb-tab-panel"
						>
							{user?.is_group_and_store_permission ? (
								<Tab label={<div>Groups & Stores</div>} className="glb-tab-label" {...a11yProps(0)} />
							) : null}
							{user?.is_feature_permission ? (
								<Tab label={<div>Features</div>} className="glb-tab-label" {...a11yProps(1)} />
							) : null}
							{user?.is_component_permission ? (
								<Tab label={<div>Components</div>} className="glb-tab-label" {...a11yProps(2)} />
							) : null}
						</Tabs>
					</Box>
					<TabPanel value={value} index={user?.is_group_and_store_permission ? 0 : 2}>
						<GroupOwnersGrid />
					</TabPanel>
					<TabPanel
						value={value}
						index={
							user?.is_group_and_store_permission
								? user.is_feature_permission
									? 1
									: 2
								: user?.is_feature_permission
								? 0
								: 2
						}
					>
						<PermissionManagementGrid
							handleChangeUserPermission={handleChangeUserPermission}
							gridProps={groupOwners}
							columnDefs={columnFeatureDefs}
							tabValue={"feature"}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={
							user?.is_group_and_store_permission
								? user.is_feature_permission
									? 2
									: 1
								: user?.is_feature_permission
								? 1
								: 0
						}
					>
						<PermissionManagementGrid
							handleChangeUserPermission={handleChangeUserPermission}
							gridProps={groupOwners}
							columnDefs={columnComponentDefs}
							tabValue={"component"}
						/>
					</TabPanel>
				</Box>
			</div>
		</div>
	);
};

export default PermissionManagement;
