import { Grid } from "@material-ui/core";
import React from "react";
import { ILegScanProps } from "../../../../../../../@types/components/legScanDetailsprops.types";

const CustomerDetailCard = ({ legScan }: ILegScanProps) => {
	return (
		<div className="report-customer-details">
			<Grid container>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<h3 color="white">Name</h3>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<p>{legScan.customer?.first_name + " " + legScan.customer?.last_name}</p>
				</Grid>
			</Grid>
			<hr style={{ borderTop: "1px dashed #201658" }}></hr>
			<Grid container>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<h3>Age</h3>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<p>{legScan.customer?.age_range}</p>
				</Grid>
			</Grid>
			<hr style={{ borderTop: "1px dashed #201658" }}></hr>
			<Grid container>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<h3>Gender</h3>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<p>{legScan.customer?.gender}</p>
				</Grid>
			</Grid>
			<hr style={{ borderTop: "1px dashed #201658" }}></hr>
			<Grid container>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<h3>Contact Number</h3>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<p>{legScan.customer?.phone}</p>
				</Grid>
			</Grid>
		</div>
	);
};

export default CustomerDetailCard;
