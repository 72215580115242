// eslint-disable-next-line prettier/prettier
// eslint-disable-next-line arrow-parens
const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
};

export default a11yProps;
