const prefixCharacterAddition = (value: number): string => {
	let returnValue: string;
	if (value < 10) returnValue = "0" + value;
	else returnValue = value as unknown as string;

	return returnValue;
};

export const UIFormattedDate = (date: Date): string => {
	const formattedDate =
		prefixCharacterAddition(date.getMonth() + 1) +
		"-" +
		prefixCharacterAddition(date.getDate()) +
		"-" +
		date.getFullYear();

	return formattedDate;
};

export const dateTimeZoneConverter = (_date: string): Date | string => {
	let timeZoneFormattedDate: Date;
	const date = new Date(_date);

	const userTimeZoneOffset = date.getTimezoneOffset() * 60000; // Converting the TimezoneOffset() to milliseconds

	if (userTimeZoneOffset >= 0) timeZoneFormattedDate = new Date(date.getTime() + userTimeZoneOffset);
	else timeZoneFormattedDate = new Date(date.getTime() - userTimeZoneOffset);

	return UIFormattedDate(timeZoneFormattedDate);
};

export const handleDateCellRenderer = ({ value }: { value: string | null | false | undefined }): JSX.Element => {
	if (value === false || value === undefined || value === "CURRENT" || value === "FUTURE") {
		return <></>;
	}
	if (value?.length === 0) {
		return <>N/A</>;
	}
	return <div>{value ? (dateTimeZoneConverter(value) as string) : "N/A"}</div>;
};
