/* eslint-disable arrow-parens */
/* eslint-disable react/prop-types */
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const useStyles = makeStyles(() => ({
	formControl: {
		"& .MuiInputBase-root": {
			color: "#28a745",
			borderColor: "#28a745",
			borderWidth: "1px",
			borderStyle: "solid",
			borderRadius: "100px",
			minWidth: "120px",
			justifyContent: "right",
			backgroundColor: "rgba(40,167,69,.12549019607843137)"
		},
		"& .MuiSelect-select.MuiSelect-select": {
			paddingTop: "8px",
			paddingBottom: "8px",
			paddingLeft: "8px",
			paddingRight: "26px"
		}
	},
	select: {
		// width: "auto",
		fontSize: "0.8rem",
		fontWeight: 500,
		"&:focus": {
			backgroundColor: "transparent"
		}
	},
	selectIcon: {
		color: "#28a745",
		fontSize: "1.5rem",
		paddingRight: "4px"
	},
	paper: {
		borderRadius: 12,
		marginTop: 8
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,

		"& li": {
			fontWeight: 400,
			paddingTop: 8,
			paddingBottom: 8,
			fontSize: "0.8rem"
		},
		"& li.Mui-selected": {
			color: "white",
			background: "#28a745"
		},
		"& li.Mui-selected:hover": {
			background: "#28a745"
		}
	}
}));

const DropDown = ({ selectedAssignedGroup, handleGroupChange, assignedGroups }) => {
	const classes = useStyles();
	const [isButtonSelected, setIsButtonSelected] = useState(true);
	// const { group_number } = selectedAssignedGroup;
	// console.log("Group Number", group_number);

	const menuProps = {
		classes: {
			list: classes.list,
			paper: classes.paper
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "center"
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "center"
		},
		getContentAnchorEl: null
	};

	// console.log("Assigned Groups", assignedGroups);
	// console.log("Selected Assigned Group", selectedAssignedGroup);

	return (
		<div className="drop-down-container">
			<FormControl className={classes.formControl}>
				<Select
					onChange={handleGroupChange}
					value={selectedAssignedGroup?.group_number}
					disableUnderline
					IconComponent={ExpandMoreRoundedIcon}
					MenuProps={menuProps}
					classes={{
						select: classes.select,
						icon: classes.selectIcon
					}}
				>
					{assignedGroups.map((assignedGroup) => (
						<MenuItem key={assignedGroup.name} value={assignedGroup.group_number}>
							{assignedGroup.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default DropDown;
