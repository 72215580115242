import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ICustomerCount } from "../../../../@types/interfaces/customerCount.interface";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart: React.FC<ICustomerCount> = ({ registeredCustomersCount, unRegisteredCustomersCount }) => {
	const data = {
		labels: ["Registered", "UnRegistered"],
		datasets: [
			{
				data: [registeredCustomersCount, unRegisteredCustomersCount], // Replace these values with your actual gender ratio data
				backgroundColor: ["#A06CD5", "#E2CFEA"],
				hoverBackgroundColor: ["#8537d4", "#dab2eb"]
			}
		]
	};

	const options = {
		plugins: {
			legend: {
				display: true,
				position: "bottom"
			}
		}
	};

	return (
		<div>
			{/* <h2>Gender Ratio</h2> */}
			<Pie data={data} options={options as any} />
		</div>
	);
};

export default PieChart;
