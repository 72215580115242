import ROLES from "./roles";

import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import BusinessIcon from "@material-ui/icons/Business";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ChatIcon from "@material-ui/icons/Chat";
import WorkIcon from "@material-ui/icons/Work";
import ReceiptIcon from "@material-ui/icons/Receipt";
import UpdateIcon from "@material-ui/icons/Update";
import ExploreIcon from "@material-ui/icons/Explore";
import AssessmentIcon from "@material-ui/icons/Assessment";
import GroupIcon from "@material-ui/icons/Group";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import HealingIcon from "@material-ui/icons/Healing";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PortraitIcon from "@material-ui/icons/Portrait";
import SettingsIcon from "@material-ui/icons/Settings";
import { SidebarOptions } from "../@types/sidebarOptions.types";

const sidebarOptions: SidebarOptions = {
	"SUPER ADMIN": [
		{
			caption: "Dashboard",
			route: "/",
			icon: () => <SupervisorAccountIcon />,
			subTabs: []
		},
		{
			caption: "Groups",
			route: "/groups",
			icon: () => <BusinessIcon />,
			subTabs: []
		},
		{
			caption: "Customers",
			route: "/customers",
			icon: () => <SupervisedUserCircleIcon />,
			subTabs: []
		},
		{
			caption: "Settings",
			route: "/",
			icon: () => <WorkIcon />,
			subTabs: [
				{
					caption: "User Management",
					route: "/settings/user-management",
					icon: () => <WorkIcon />,
					subTabs: []
				},
				{
					caption: "Device Management",
					route: "/settings/devices",
					icon: () => <WorkIcon />,
					subTabs: []
				},
				{
					caption: "Product Management",
					route: "/settings/products",
					icon: () => <WorkIcon />,
					subTabs: []
				},
				{
					caption: "Disease Management",
					route: "/settings/disease",
					icon: () => <WorkIcon />,
					subTabs: []
				},
				{
					caption: "Permission Management",
					route: "/settings/permission-management",
					icon: () => <WorkIcon />,
					subTabs: []
				}
			]
		},
		{
			caption: "Reports",
			route: "/reports",
			icon: () => <ReceiptIcon />,
			subTabs: []
		}
	],
	ADMIN: [
		{
			caption: "Dashboard",
			route: "/admin",
			icon: () => <SupervisorAccountIcon />,
			subTabs: []
		},
		{
			caption: "Customers",
			route: "/admin/customers",
			icon: () => <SupervisedUserCircleIcon />,
			subTabs: []
		},
		{
			caption: "Branches",
			route: "/admin/stores",
			icon: () => <ExploreIcon />,
			subTabs: []
		},
		{
			caption: "Send SMS",
			route: "/admin/send-text",
			icon: () => <ChatIcon />,
			subTabs: []
		},
		{
			caption: "Settings",
			route: "/",
			icon: () => <SettingsIcon />,
			subTabs: [
				{
					caption: "User Management",
					subTabs: [],
					icon: () => <PortraitIcon />,
					route: "/admin/user-management"
				},
				{
					caption: "Permission Management",
					subTabs: [],
					icon: () => <PortraitIcon />,
					route: "/admin/permission-management"
				},
				{
					caption: "Device Management",
					subTabs: [],
					icon: () => <PortraitIcon />,
					route: "/admin/device"
				},
				{
					caption: "Product Management",
					subTabs: [],
					icon: () => <PortraitIcon />,
					route: "/admin/products"
				}
			]
		},
		{
			caption: "Reports",
			route: "/admin/report",
			icon: () => <ReceiptIcon />,
			subTabs: []
		}
	],
	"STORE ADMIN": [
		{
			caption: "Customers",
			route: "/store-admin/customers",
			icon: () => <SupervisorAccountIcon />,
			subTabs: []
		},
		{
			caption: "Device Management",
			route: "/store-admin/device",
			icon: () => <PortraitIcon />,
			subTabs: []
		}
	],
	DOCTOR: [
		{
			caption: "Dashboard",
			route: "/",
			icon: () => <SupervisorAccountIcon />,
			subTabs: []
		}
	]
};

export default sidebarOptions;
