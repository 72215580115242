/* eslint-disable arrow-parens */
import { FC, useCallback, useState } from "react";
import { Logo } from "../../../assets";
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import ThemeTextField from "../../shared/theme/textField/ThemeTextField";
import ThemeButton from "../../shared/theme/button/Button";
import { AiOutlineLogin, AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ROLES from "../../../constants/roles";

const labels = {
	first_name: "First Name",
	last_name: "Last Name",
	phone_number: "Phone Number",
	user_name: "Username",
	email: "Email",
	password: "Password",
	group_number: "Group Number"
};

const SignUp: FC = (): JSX.Element => {
	const [user, setUser] = useState({
		first_name: "",
		last_name: "",
		// phone_number: "",
		role: "",
		user_name: "",
		email: "",
		password: "",
		group_number: null
	});
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const toggleShowPassword = useCallback(() => {
		setShowPassword(!showPassword);
	}, [showPassword]);

	const handleUserChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			setUser(
				Object.assign({}, user, {
					[name]: value
				})
			);
		},
		[user]
	);

	const handleSelectChange = useCallback(
		(event: any) => {
			const { name, value } = event.target;
			setUser(
				Object.assign({}, user, {
					[name]: value
				})
			);
		},
		[user]
	);

	console.log(user);

	const handleSubmit = useCallback(async () => {
		try {
			const response = await axios.post("http://localhost:4008/api/v1/auth/admin-registration", {
				...user
			});
			if (response) {
				alert("Succesfully Signed Up");
				navigate("/login");
			}
		} catch (err) {
			throw err;
		}
	}, [user]);

	return (
		<div className="signup auth" style={{ marginBottom: 0 }}>
			<div className="auth-container" style={{ marginBottom: 0 }}>
				<img src={Logo} className="auth-img" style={{ marginTop: "-3.5rem" }} />
				<Paper elevation={3} className="auth-paper-container" style={{ marginBottom: 0 }}>
					{/* <div className="auth-text theme-primary-color">Sign Up</div> */}
					{/* <div className="theme-section-margin" /> */}
					<FormControl variant="outlined" style={{ width: "50%" }}>
						<InputLabel id="demo-simple-select-outlined-label">Your Role</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							label="Your Role"
							name="role"
							onChange={(e) => handleSelectChange(e)}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							<MenuItem value={ROLES.super_admin}>{ROLES.super_admin}</MenuItem>
							<MenuItem value={ROLES.admin}>{ROLES.admin}</MenuItem>
							<MenuItem value={ROLES.doctor}>{ROLES.doctor}</MenuItem>
						</Select>
					</FormControl>
					{Object.entries(user).map(([key, value], index) => {
						return (
							<>
								<FormControl className="fullwidth" key={index}>
									{user.role !== ROLES.super_admin ? (
										<InputLabel shrink htmlFor="bootstrap-input" className="theme-input-label">
											{labels[key as keyof typeof labels]}
										</InputLabel>
									) : key === "group_number" ? null : (
										<InputLabel shrink htmlFor="bootstrap-input" className="theme-input-label">
											{labels[key as keyof typeof labels]}
										</InputLabel>
									)}
									{user.role !== ROLES.super_admin ? (
										<ThemeTextField
											value={value}
											name={key}
											type={key === "password" ? (showPassword ? "text" : "password") : "text"}
											onChange={handleUserChange}
											endAdornment={
												key === "password" ? (
													showPassword ? (
														<InputAdornment
															position="end"
															style={{
																position: "absolute",
																right: 0
															}}
														>
															<IconButton
																// sstyle={{ position: "absolute" }}
																onClick={toggleShowPassword}
															>
																<AiFillEye />
															</IconButton>
														</InputAdornment>
													) : (
														<InputAdornment
															position="end"
															style={{
																position: "absolute",
																right: 0
															}}
														>
															<IconButton onClick={toggleShowPassword}>
																<AiFillEyeInvisible />
															</IconButton>
														</InputAdornment>
													)
												) : null
											}
										/>
									) : key === "group_number" ? null : (
										<ThemeTextField
											value={value}
											name={key}
											type={key === "password" ? (showPassword ? "text" : "password") : "text"}
											onChange={handleUserChange}
											endAdornment={
												key === "password" ? (
													showPassword ? (
														<InputAdornment
															position="end"
															style={{
																position: "absolute",
																right: 0
															}}
														>
															<IconButton
																// sstyle={{ position: "absolute" }}
																onClick={toggleShowPassword}
															>
																<AiFillEye />
															</IconButton>
														</InputAdornment>
													) : (
														<InputAdornment
															position="end"
															style={{
																position: "absolute",
																right: 0
															}}
														>
															<IconButton onClick={toggleShowPassword}>
																<AiFillEyeInvisible />
															</IconButton>
														</InputAdornment>
													)
												) : null
											}
										/>
									)}
								</FormControl>
								<div className="theme-form-margin" />
							</>
						);
					})}
					<div className="theme-section-margin" />
					<ThemeButton onClick={handleSubmit}>
						Sign Up{" "}
						<span className="button-icon">
							<AiOutlineLogin />
						</span>
					</ThemeButton>
				</Paper>
			</div>
		</div>
	);
};

export default SignUp;
