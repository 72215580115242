import ProductGrid from "./productGrid/ProductGrid";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const ProductManagement = () => {
	const navigate = useNavigate();
	const handleAddProductButtonClick = () => {
		const path = "/settings/add-product";
		navigate(path);
	};
	return (
		<div>
			<div className="pf-action-button-container" id="pf-action-button-container">
				<Button
					className="button-green"
					variant="contained"
					onClick={handleAddProductButtonClick}
					style={{
						cursor: "pointer",
						pointerEvents: "unset"
					}}
				>
					<span className="button-label-with-icon">Add Products</span>
					<span>
						<AddCircleIcon className="button-icon" />
					</span>
				</Button>
			</div>
			<ProductGrid />
		</div>
	);
};

export default ProductManagement;
