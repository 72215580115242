import { FC, useCallback, useContext, useState } from "react";
import { Card, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import "./login.css";
import { Logo } from "../../../assets";
import ThemeTextField from "../../shared/theme/textField/ThemeTextField";
import ThemeButton from "../../shared/theme/button/Button";
import { AiFillEye, AiFillEyeInvisible, AiOutlineLogin } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ROLES from "../../../constants/roles";
import { AuthContext } from "../../../contexts";
import { api } from "../../../utils/api";

const Login: FC = (): JSX.Element => {
	const navigate = useNavigate();
	const { setUser } = useContext(AuthContext);
	const [credentialsPayload, setCredentialsPayload] = useState({
		username: "",
		password: "",
		role: ""
	});
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = useCallback(() => {
		setShowPassword(!showPassword);
	}, [showPassword]);

	const handleCredentialChange = useCallback(
		(event: any) => {
			const { name, value } = event.target;
			setCredentialsPayload(
				Object.assign({}, credentialsPayload, {
					[name]: value
				})
			);
		},
		[credentialsPayload]
	);

	const handleSubmitClick = useCallback(async () => {
		const payload = {
			password: credentialsPayload.password,
			user_id: credentialsPayload.username,
			role: credentialsPayload.role
		};
		const response = await api.auth.login(payload);
		if (response) {
			localStorage.setItem("@user", response._id);
			// console.log("=====>response", response);
			console.log("====>role", response.group_number);
			setUser(response);
			if (response.role === ROLES.admin && response.group_number) {
				console.log("admin login");
				navigate(`/admin?gn=${response.group_number}`);
			} else navigate("/");
		}
	}, [credentialsPayload.password, credentialsPayload.username, credentialsPayload.role, setUser, navigate]);

	return (
		<div className="login">
			<div className="login-container">
				<div className="logo-container">
					<img src={Logo} />
				</div>
				<Paper elevation={3} className="auth-paper-container" style={{ position: "relative" }}>
					<div className="auth-text theme-primary-color">Login Now</div>
					<div className="theme-section-margin" />
					<FormControl variant="outlined" style={{ width: "50%" }}>
						<InputLabel id="demo-simple-select-outlined-label">Your Role</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							label="Your Role"
							name="role"
							// eslint-disable-next-line prettier/prettier
							onChange={e => handleCredentialChange(e)}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							<MenuItem value={ROLES.super_admin}>{ROLES.super_admin}</MenuItem>
							<MenuItem value={ROLES.admin}>{ROLES.admin}</MenuItem>
							<MenuItem value={ROLES.doctor}>{ROLES.doctor}</MenuItem>
						</Select>
					</FormControl>
					<FormControl className="fullwidth">
						<InputLabel shrink htmlFor="bootstrap-input" className="theme-input-label">
							Username
						</InputLabel>
						<ThemeTextField
							name="username"
							value={credentialsPayload.username}
							onChange={handleCredentialChange}
						/>
					</FormControl>
					<div className="theme-form-margin" />
					<FormControl className="fullwidth">
						<InputLabel shrink htmlFor="bootstrap-input" className="theme-input-label">
							Password
						</InputLabel>
						<ThemeTextField
							name="password"
							type={showPassword ? "text" : "password"}
							value={credentialsPayload.password}
							onChange={handleCredentialChange}
							endAdornment={
								showPassword ? (
									<InputAdornment
										position="end"
										style={{
											position: "absolute",
											right: 0
										}}
									>
										<IconButton
											// sstyle={{ position: "absolute" }}
											onClick={toggleShowPassword}
										>
											<AiFillEye />
										</IconButton>
									</InputAdornment>
								) : (
									<InputAdornment
										position="end"
										style={{
											position: "absolute",
											right: 0
										}}
									>
										<IconButton onClick={toggleShowPassword}>
											<AiFillEyeInvisible />
										</IconButton>
									</InputAdornment>
								)
							}
						/>
					</FormControl>
					<div className="theme-section-margin" />
					<ThemeButton onClick={handleSubmitClick}>
						Login{" "}
						<span className="button-icon">
							<AiOutlineLogin />
						</span>
					</ThemeButton>
					<div className="auth-actions">
						<div className="theme-section-margin" />
						<div>
							<Link to={"/forget-password"}>Forget Password?</Link>
						</div>
						<div className="theme-form-margin" />
						<div>
							<Link to={"/forget-userid"}>Forget Username?</Link>
						</div>
					</div>
				</Paper>
			</div>
		</div>
	);
};

export default Login;
