import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { ImageAnalysisProps } from "../../../../../../../../@types/components/ImageAnalysisProps.types";
import DataCard from "../../../../../../../shared/dataCard/DataCard";
import { defaultImage } from "../../../../../../../../assets";

const ImageAnalysisContainer = ({
	topImage,
	sideImage,
	title,
	feet_length,
	feet_width,
	feet_heel_width,
	feet_instep_height,
	feet_arc_height,
	arc_type
}: ImageAnalysisProps) => {
	return (
		<Paper className="imageAnalysis" style={{ padding: "2rem", backgroundColor: "#F8F8FF" }} elevation={3}>
			<h2 style={{ color: "#003545" }}>{title}</h2>
			<Grid container justifyContent="center">
				<Grid item xs={6} sm={6} md={12} lg={6} xl={6}>
					<div className="feetImage ">
						<Grid container justifyContent="center" alignItems="center">
							<Grid item sm={1} md={1} lg={1} xl={1}>
								<div className="len-label">
									<p className="label" style={{ whiteSpace: "nowrap", marginTop: "50%" }}>
										Feet Length:
										<span className="label-number-item">{feet_length}mm</span>
									</p>
								</div>
							</Grid>
							<Grid item sm={11} md={11} lg={11} xl={11}>
								<div>
									<p className="label">
										Feet Width:
										<span className="label-number-item">{feet_width} mm</span>
									</p>
									<img src={topImage ? topImage : defaultImage} />
									<p className="label">
										Heel Width: <span className="label-number-item">{feet_heel_width} mm</span>
									</p>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<div className="right-image-section">
						<div className="feetImage sideFeet">
							<div>
								<p className="label top">
									Instep Height:
									<span className="label-number-item">{feet_instep_height} mm</span>
								</p>
								<img src={sideImage ? sideImage : defaultImage} />
								<p className="label bottom">
									Arch Height:
									<span className="label-number-item">{feet_arc_height} mm</span>
								</p>
							</div>
						</div>
						<DataCard arc_type={arc_type} />
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default ImageAnalysisContainer;
