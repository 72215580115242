import { StatusCodes } from "http-status-codes";
import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";
import { IFootcareDevice } from "../../../@types/device.interface";

const { post, get, patch } = request;
const { Authorization, Bearer } = AUTHORIZATION;

const initialRoute = "devices";

const addDevice = async (_payload: IFootcareDevice) => {
	try {
		const payload = JSON.stringify(_payload);
		const endpoint = `${initialRoute}/add-device`;
		const token = localStorage.getItem("@jwt");
		const response = await post(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;

			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;

			alert(message);
		} else {
			throw error;
		}
	}
};

const getDevice = async (filterQuery: any) => {
	try {
		const endpoint = `${initialRoute}/get-device`;
		const token = localStorage.getItem("@jwt");
		const response = await get(
			endpoint,
			{
				...headers,
				[Authorization]: `${Bearer} ${token}`
			},
			filterQuery
		);
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;

			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;

			alert(message);
		} else {
			throw error;
		}
	}
};
const updateDevice = async (_payload: IFootcareDevice) => {
	try {
		const endpoint = `${initialRoute}/update-device`;
		const token = localStorage.getItem("@jwt");
		const response = await patch(endpoint, _payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.patch.succ) {
				const {
					data: { result }
				} = response;
				console.log(response);
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;

			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;

			alert(message);
		} else {
			throw error;
		}
	}
};

export const deviceManagement = {
	addDevice,
	getDevice,
	updateDevice
};
