import React, { useCallback, useEffect, useState } from "react";
import { Idashboard } from "../../../@types/interfaces/dashboard.interface";
import { api } from "../../api";
import useQuerySearch from "../../../hooks/querySearch/useQuerySearch";

const useGetDashboardData = (groupNumber?: number) => {
	const [dashboardData, setDashboardData] = useState<Idashboard | null>(null);

	const group_number = useQuerySearch("gn");
	const getDashboardList = useCallback(async () => {
		const filter: any = { group_number };
		if (groupNumber) {
			filter.group_number = groupNumber;
		}
		const response = await api.dashboard.getDashboardData(filter);
		if (response) {
			setDashboardData(response);
		}
	}, [group_number, groupNumber]);

	useEffect(() => {
		getDashboardList();
	}, [getDashboardList]);
	return { dashboardData };
};

export default useGetDashboardData;
