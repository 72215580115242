/* eslint-disable prettier/prettier */
/* eslint-disable arrow-parens */
import { useState, useCallback, useEffect, useContext, useRef, Suspense } from "react";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../constants/caption/dashboardHeader";
import { AuthContext, UIContext } from "../../../../../contexts";
import a11yScrollableProps from "../../../../../constants/tabPanelProps/ayScrollableProps";
import ScrollTabPanel from "../../../../shared/tabPanelComponent/ScrollTabPanel";

import { Box, Tab, Tabs, Badge, withStyles, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import initCapitalize from "../../../../../utils/commonFunctions/initCapitalize";

import useRouteParam from "../../../../../hooks/useRouteParam";
import ExtractRouteParams from "../../../../../utils/commonFunctions/extractRouteParams";
import { trackPromise } from "react-promise-tracker";
import {
	LazyCustomerDiagonisisDetails,
	LazyCustomerNotesDetails,
	LazyCustomerProductRecommendationDetails,
	LazyCustomerProfileDetails,
	LazyCustomerScanDetails,
	LazyCustomerSendMessage
} from "../../..";
import { CUSTOMER_TABS } from "../../../../../constants/customerDetailsTabsOption/customerDetailsTabsOption";
import { GreenCheckbox } from "../../../../shared/greenCheckBox/GreenCheckBox";
import DownloadReport from "../../../../shared/downloadReport/DownloadReport";
// import { api } from "../../../../../utils/api";

const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: "#b8ddc0",
		color: "#558b2f",
		fontWeight: "bold",
		// boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		top: 3,
		left: -15,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			// animation: "$ripple 1.2s infinite ease-in-out",
			// border: "1px solid currentColor",
			// eslint-disable-next-line quotes
			content: '""'
		}
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0
		}
	}
}))(Badge);

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
		// backgroundColor: theme.palette.background.paper
	}
}));

const CustomerDetails = (): JSX.Element => {
	const classes = useStyles();
	const [value, setValue] = useState(0);
	const { setDashboardHeader, setCustomerUI } = useContext(UIContext);
	const { user } = useContext(AuthContext);
	const [dataChecked, setDataChecked] = useState<boolean>(false);
	// const querySSN = useRouteParam("qs");
	// const queryTo = useRouteParam("to");
	// const queryGroupNumber = useRouteParam("gn");
	// const routeParam = ExtractRouteParams(); // This is to extract the route after ?(Question Mark)
	const [customer, setCustomer] = useState<any>();

	const handleLabelChange = (event: any, value: number) => {
		setValue(value);
	};

	const handleDataCheckChange = () => {
		setDataChecked(!dataChecked);
	};

	useEffect(() => {
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.customer_details);
	}, [setDashboardHeader]);

	// useEffect(() => {
	// 	if (querySSN && queryGroupNumber) {
	// 		getcustomersByEmployeeSSN(querySSN as string, queryGroupNumber as number);
	// 	}
	// }, [getCountNotesForEachcustomer, getcustomersByEmployeeSSN, getPaycheckByEmployeeSSN, queryGroupNumber, querySSN]);

	// if (!customer) {
	// 	return <></>;
	// }

	return (
		<div className="customer-details-container" id="customer-details-container">
			<div className="customer-details-tab-panel-container" style={{ width: "auto" }}>
				<div className={classes.root + "glb-tab-panel-container"}>
					<div className="inline-flex" style={{ justifyContent: "flex-end" }}>
						<DownloadReport />
						{/* <Switch
							checked={dataChecked}
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onChange={handleDataCheckChange}
							name="checkedA"
							inputProps={{ "aria-label": "secondary checkbox" }}
						/> */}
					</div>
					<Box sx={{ width: "100%" }}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs
								value={value}
								onChange={handleLabelChange}
								TabIndicatorProps={{
									style: {
										border: "none",
										backgroundColor: "#25a18e"
									}
								}}
								variant="scrollable"
								scrollButtons="auto"
								aria-label="scrollable auto tabs example"
								className="glb-tab-panel"
							>
								{user?.feature_permission?.is_scan_details ? (
									<Tab
										label={"Scan Details"}
										className="glb-tab-label"
										{...a11yScrollableProps(0)}
										key={0}
									/>
								) : null}

								{user?.feature_permission?.is_diagnosis_details ? (
									<Tab
										label={"Diagnosis Questionnaire"}
										className="glb-tab-label"
										{...a11yScrollableProps(1)}
										key={1}
									/>
								) : null}
								{user?.feature_permission?.is_product_recommendation_details ? (
									<Tab
										label={"Product Recommendation"}
										className="glb-tab-label"
										{...a11yScrollableProps(2)}
										key={2}
									/>
								) : null}
								<Tab label={"Notes"} className="glb-tab-label" {...a11yScrollableProps(3)} key={3} />
								<Tab
									label={"Send Text"}
									className="glb-tab-label"
									{...a11yScrollableProps(4)}
									key={3}
								/>
							</Tabs>
							{/* <ScrollTabPanel value={value} index={0}>
								<Suspense fallback={<div />}>
									<LazyCustomerProfileDetails customer={customer} />
								</Suspense>
							</ScrollTabPanel> */}
							{user ? (
								<>
									<ScrollTabPanel
										value={value}
										index={user?.feature_permission.is_scan_details ? 0 : 2}
									>
										<Suspense fallback={<div />}>
											<LazyCustomerScanDetails state="ROUTE" />
										</Suspense>
									</ScrollTabPanel>

									<ScrollTabPanel
										value={value}
										index={
											user?.feature_permission.is_scan_details
												? user?.feature_permission.is_diagnosis_details
													? 1
													: 2
												: user?.feature_permission.is_diagnosis_details
												? 0
												: 2
										}
									>
										<Suspense fallback={<div />}>
											<LazyCustomerDiagonisisDetails />
										</Suspense>
									</ScrollTabPanel>
									<ScrollTabPanel
										value={value}
										index={
											user?.feature_permission.is_scan_details
												? user?.feature_permission.is_diagnosis_details
													? 2
													: 1
												: user?.feature_permission.is_diagnosis_details
												? 1
												: 0
										}
									>
										<Suspense fallback={<div />}>
											<LazyCustomerProductRecommendationDetails />
										</Suspense>
									</ScrollTabPanel>
									<ScrollTabPanel
										value={value}
										index={
											user?.feature_permission.is_scan_details
												? user?.feature_permission.is_diagnosis_details
													? 3
													: 1
												: user?.feature_permission.is_diagnosis_details
												? 1
												: 0
										}
									>
										<Suspense fallback={<div />}>
											<LazyCustomerNotesDetails />
										</Suspense>
									</ScrollTabPanel>
									<ScrollTabPanel
										value={value}
										index={
											user?.feature_permission.is_scan_details
												? user?.feature_permission.is_diagnosis_details
													? 4
													: 1
												: user?.feature_permission.is_diagnosis_details
												? 1
												: 0
										}
									>
										<Suspense fallback={<div />}>
											<LazyCustomerSendMessage />
										</Suspense>
									</ScrollTabPanel>
								</>
							) : null}
						</Box>
					</Box>
				</div>
			</div>
		</div>
	);
};

export default CustomerDetails;
