import { lazy } from "react";

// Super Admin
export { default as SuperAdminSidebar } from "./superAdmin/sidebar/SuperAdminSidebar";
export { default as DeviceManagement } from "./superAdmin/settings/deviceManagement/DeviceManagement";
export { default as Groups } from "./superAdmin/groups/Groups";
export { default as Customers } from "./superAdmin/customers/Customers";

//Admin
export { default as AdminSidebar } from "./admin/adminSidebar/AdminSidebar";
export { default as GroupCustomers } from "./admin/customers/GroupCustomers";
export { default as RegisteredCustomerScanDetails } from "./admin/customers/registeredCustomerScanDetails/RegisteredCustomerScanDetails";
export { default as CustomerDetails } from "./admin/customers/customerDetails/CustomerDetails";
export const LazyCustomerScanDetails = lazy(
	() => import("./admin/customers/customerDetails/customerScanDetails/CustomerScanDetails")
);
export const LazyCustomerProfileDetails = lazy(
	() => import("./admin/customers/customerDetails/customerProfileDetails/CustomerProfileDetails")
);

export const LazyCustomerDiagonisisDetails = lazy(
	() => import("./admin/customers/customerDetails/customerDiagonisisDetails/CustomerDiagonisisDetails")
);
export const LazyCustomerProductRecommendationDetails = lazy(
	() => import("./admin/customers/customerDetails/productSuggestion/ProductSuggestion")
);
export const LazyCustomerNotesDetails = lazy(() => import("./admin/customers/customerDetails/notes/Notes"));
export const LazyCustomerSendMessage = lazy(() => import("./admin/customers/customerDetails/sendMessage/SendMessage"));
