import { dateConverterToUSFormat, dateTimeZoneConverter } from "./date";
import { dollarize, numberToDollarStr } from "./dollar";
import initCapitalize from "./initCapitalize";

export const handleDateCellRenderer = ({ value }: { value: string | null | false | undefined }): JSX.Element => {
	if (value === false || value === undefined || value === "CURRENT" || value === "FUTURE") {
		return <></>;
	}
	if (value?.length === 0) {
		return <>N/A</>;
	}
	return <div>{value ? (dateTimeZoneConverter(value) as string) : "N/A"}</div>;
};

export const handlePlanEndDateCellRenderer = ({ value }: { value: string }): JSX.Element => {
	return <div>{value ? (dateTimeZoneConverter(value) as string) : "CURRENT"}</div>;
};

export const handleDollarCellRenderer = ({ value }: { value: number }): JSX.Element => {
	// if (value === false || value === undefined) {
	// 	return <></>;
	// }

	return <div>{dollarize(value)}</div>;
};

export const handleCapitalizeCellRenderer = ({ value }: { value: string }) => {
	const capitalize = initCapitalize(value);
	return <div>{capitalize}</div>;
};

export const handleNameCellRenderer = ({ value }: { value: any }) => {
	let name;
	if (value.middle_name) name = value.first_name + " " + value.middle_name + " " + value.last_name;
	else name = value.first_name + " " + value.last_name;
	return <div>{value ? name : "N/A"}</div>;
};

export const handleValueCellRenderer = ({ value }: { value: string }) => {
	return <div>{value ? value : "N/A"}</div>;
};

export const handleLastUpdateDateCellRenderer = ({ value }: { value: string }) =>
	dateConverterToUSFormat(value) as string;
