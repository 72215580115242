/* eslint-disable @typescript-eslint/ban-types */
import { Button, ButtonProps, ButtonTypeMap, ExtendButtonBase } from "@material-ui/core";
import { FC } from "react";
import "./button.css";

const ThemeButton: FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
	return (
		<div className="theme-button">
			<Button className="button" {...props} />
		</div>
	);
};

export default ThemeButton;
