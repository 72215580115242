import { useState, useCallback, useEffect } from "react";
import {
	TextField,
	InputAdornment,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Checkbox,
	withStyles,
	ListItemIcon,
	ListItem,
	Divider,
	List,
	ListItemText,
	CheckboxProps,
	Breadcrumbs,
	Link
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useDebouncedCallback } from "use-debounce";

import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import "./treeView.css";
import { useEmployerRoute } from "../../../../../hooks";
import useRouteParam from "../../../../../hooks/useRouteParam";
import { useNavigate } from "react-router-dom";

const TreeView = ({ handleAssignGroup, handleAssignStore, handleSelectProfile, groups }: any) => {
	const [filteredGroups, setFilteredGroups] = useState<any[]>([]);
	const isEmployerRoute = useEmployerRoute();
	const navigate = useNavigate();

	const DarkCheckbox = withStyles({
		root: {
			color: "#757575",
			"&$checked": {
				color: "#757575"
			}
		},
		checked: {}
	})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

	const GreenCheckbox = withStyles({
		root: {
			color: "#25a18e",
			"&$checked": {
				color: "#25a18e"
			}
		},
		checked: {}
	})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

	const filterGroups = useDebouncedCallback(
		useCallback(
			(searchQuery: string) => {
				const _filteredGroups: any[] = [];
				groups &&
					groups.forEach((group: any) => {
						const { name } = group;
						if (name && name.toLowerCase().indexOf(searchQuery) !== -1) {
							_filteredGroups.push(group);
						}
					});
				setFilteredGroups(_filteredGroups);
			},
			[groups]
		),
		500
	);

	const handleSearchChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			const searchQuery = value.toLowerCase();
			filterGroups(searchQuery);
		},
		[filterGroups]
	);

	useEffect(() => {
		console.log("groups from use effect", groups);
		groups && setFilteredGroups(groups);
	}, [groups]);

	console.log("groups from tree view", groups);

	console.log("groups from tree", filterGroups);

	return (
		<div className="tree-view">
			<div className="breadcrum-text-container" id="breadcrum-text">
				<Breadcrumbs
					separator={<NavigateNextIcon fontSize="small" style={{ color: "#e0e0e0" }} />}
					aria-label="breadcrumb"
				>
					<Link className="breadcrumb-link-text">User Management</Link>
					<span className="breadcrumb-text">Assign Groups</span>
				</Breadcrumbs>
			</div>
			<div>
				<TextField
					id="search-groups"
					placeholder="Search Groups"
					variant="outlined"
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon style={{ color: "#7cb342" }} />
							</InputAdornment>
						)
					}}
					onChange={handleSearchChange}
				/>
			</div>

			<Divider className="actions-divider" />
			<div className="items">
				{filteredGroups.length !== 0 ? (
					filteredGroups.map((group: any, index) => {
						const { _id, group_name, assigned_stores, unassigned_stores } = group;

						return (
							<Accordion key={_id}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									className="accordion-summary-container"
								>
									<div className="accordion-summary">
										<span
											onClick={(event: React.MouseEvent<HTMLSpanElement>) =>
												event.stopPropagation()
											}
											style={{
												cursor: "not-allowed"
											}}
										>
											<GreenCheckbox
												checked={group.assigned_group_info}
												className="select-group-icon"
												onChange={() => {
													handleAssignGroup(index);
												}}
											/>
										</span>
										<span
											className="group-name"
											onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
												event.stopPropagation();
												handleSelectProfile(index);
											}}
											onFocus={(event: React.FocusEvent<HTMLSpanElement>) =>
												event.stopPropagation()
											}
										>
											{group_name}
										</span>
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<List className="location-list">
										{assigned_stores.length !== 0 &&
											assigned_stores.map((store: any, store_index: number) => {
												const { _id, store_name } = store;
												return (
													<ListItem key={_id}>
														<ListItemIcon
															onClick={() =>
																handleAssignStore(index, store_index, "ASSIGNED")
															}
														>
															<GreenCheckbox
																className="select-group-icon"
																checked={store.is_assigned}
															/>
														</ListItemIcon>
														<ListItemText
															className="list-location-text"
															primary={store_name}
															onClick={() => handleSelectProfile(group, "group")}
														/>
													</ListItem>
												);
											})}
										{unassigned_stores.length !== 0 &&
											unassigned_stores.map((store: any, store_index: number) => {
												const { _id, store_name } = store;
												return (
													<ListItem key={_id}>
														<ListItemIcon
															onClick={() =>
																handleAssignStore(index, store_index, "UNASSIGNED")
															}
														>
															<GreenCheckbox
																className="select-group-icon"
																checked={store.is_assigned}
															/>
														</ListItemIcon>
														<ListItemText
															className="list-location-text"
															primary={store_name}
															onClick={() => handleSelectProfile(group, "group")}
														/>
													</ListItem>
												);
											})}
									</List>
								</AccordionDetails>
							</Accordion>
						);
					})
				) : (
					<div className="no-result">No such groups found!</div>
				)}
			</div>
		</div>
	);
};

export default TreeView;
