import { FC, useCallback, useContext, useEffect, useState } from "react";
import ActionButton from "../../../shared/actionButton/ActionButton";
import { FormControl, Grid, InputLabel, MenuItem } from "@material-ui/core";
import ThemeTextField from "../../../shared/theme/textField/ThemeTextField";
import useQuery from "../../../../hooks/useQuery";
import axios from "axios";
import ThemeSelectField from "../../../shared/theme/select/ThemeSelectField";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../utils/api";
import { AuthContext } from "../../../../contexts";

const labels = {
	group: "Group",
	store_name: "Store Name",
	city: "City",
	address_line_1: "Address Line 1",
	address_line_2: "Address Line 2",
	state_code: "State Code",
	pincode: "Pincode",
	contact_first_name: "Contact First Name",
	contact_last_name: "Contact First Last",
	contact_phone_number: "Contact Phone Number",
	district: "District",
	GST_number: "GST Number",
	PAN: "PAN"
};

const AddStore: FC = (): JSX.Element => {
	const navigate = useNavigate();
	const [groupNumber, setGroupNumber] = useState<number | null>(null);
	const [store, setStore] = useState<null | any>({
		store_name: "",
		city: "",
		address_line_1: "",
		address_line_2: "",
		state_code: "",
		pincode: "",
		contact_first_name: "",
		contact_last_name: "",
		contact_phone_number: "",
		GST_number: "",
		PAN: "",
		district: ""
	});
	const query = useQuery();

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			setStore(
				Object.assign({}, store, {
					[name]: value
				})
			);
		},
		[store]
	);

	const handleSubmitStore = useCallback(async () => {
		try {
			const payload = {
				...store,
				group_number: groupNumber
			};
			const response = await api.stores.addStore(payload);
			if (response) {
				alert("Succesfully added Store");
				navigate(`/admin/stores?gn=${groupNumber}`);
			}
		} catch (err) {
			console.log(err);
			alert("Unable to add group");
		}
	}, [groupNumber, store]);

	useEffect(() => {
		if (query && query.get("gn")) {
			setGroupNumber(Number(query.get("gn")));
		}
	}, [query]);

	return (
		<div className="add-store">
			<ActionButton onClick={handleSubmitStore}>
				<div>Add Store</div>
			</ActionButton>
			{/* <FormControl className="fullwidth">
				<Grid container className="form-grid">
					<Grid item xl={2} lg={2} md={2} sm={6} xs={2} className="form-grid-label">
						<InputLabel shrink htmlFor="bootstrap-input" className="theme-input-label form-grid-label">
							Group
						</InputLabel>
					</Grid>
					<Grid item xl={10} lg={10} md={10} sm={6} xs={6} className="form-grid-field">
						<ThemeSelectField name={"group"} onChange={handleGroupChange} style={{ width: 400 }}>
							{groups.map((group, index) => {
								return (
									<MenuItem key={index} value={group.group_name}>
										{group.group_name}
									</MenuItem>
								);
							})}
						</ThemeSelectField>
					</Grid>
				</Grid>
			</FormControl> */}
			<div className="theme-section-margin" />
			{Object.entries(store).map(([name, value], index) => {
				return (
					<>
						<FormControl className="fullwidth" key={index}>
							<Grid container className="form-grid">
								<Grid item xl={2} lg={2} md={2} sm={6} xs={2} className="form-grid-label">
									<InputLabel
										shrink
										htmlFor="bootstrap-input"
										className="theme-input-label form-grid-label"
									>
										{labels[name as keyof typeof labels] + ":"}
									</InputLabel>
								</Grid>
								<Grid item xl={10} lg={10} md={10} sm={6} xs={6} className="form-grid-field">
									<ThemeTextField
										name={name}
										value={value}
										onChange={handleChange}
										style={{ width: 400 }}
									/>
								</Grid>
							</Grid>
						</FormControl>
						<div className="theme-section-margin" />
					</>
				);
			})}
			<ActionButton onClick={handleSubmitStore}>
				<div>Add Store</div>
			</ActionButton>
		</div>
	);
};

export default AddStore;
