import { FC, useCallback, useEffect, useRef, useState } from "react";
import useQuery from "../../../../hooks/useQuery";
import axios from "axios";
import { get } from "http";
import { Button, FormControl, InputLabel } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import ThemeTextField from "../../../shared/theme/textField/ThemeTextField";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ActionButton from "../../../shared/actionButton/ActionButton";

const labels = {
	group_id: "Group ID",
	group_name: "Group Name",
	city: "City",
	address_line_1: "Address Line 1",
	address_line_2: "Address Line 2",
	state_code: "State Code",
	pincode: "Pincode",
	contact_first_name: "Contact First Name",
	contact_last_name: "Contact First Last",
	contact_phone_number: "Contact Phone Number",
	district: "District",
	GST_number: "GST Number",
	PAN: "PAN"
};

const Group: FC = (): JSX.Element => {
	const [group, setGroup] = useState<any | null>(null);
	const query = useQuery();
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	const agGridRef = useRef<any>(null);
	const navigate = useNavigate();
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};

	const handleAgGridReady = (params: GridReadyEvent) => {
		// eslint-disable-next-line no-unused-vars
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleDeviceCountCellRenderer = ({ value }: { value: any }) => {
		const devices = value;
		const deviceCount = devices ? devices.length : 0;

		return <div>{deviceCount}</div>;
	};

	const getGroup = useCallback(async () => {
		try {
			if (query && query.get("gid")) {
				const _id = query.get("gid");
				const response = await axios.get("http://localhost:4008/api/v1/groups/get-all-groups", {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("@jwt")}`
					},
					params: {
						group_id: _id
					}
				});
				if (response.data.result[0]) {
					setGroup(Object.assign({}, response.data.result[0]));
				}
			}
		} catch (err) {
			alert("Unable to fetch group");
		}
	}, [query]);

	const handleSeeDetailsClick = useCallback(() => {
		const focusedCell = agGridRef.current.api.getFocusedCell();
		const row = agGridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
		const {
			data: { group_id, store_id }
		} = row;
		navigate(`/store?gid=${group_id}&sid=${store_id}`);
	}, [navigate]);

	const handleSeeDetailsCellRenderer = () => {
		return (
			<Button
				className="ag-grid-cell-button"
				variant="outlined"
				onClick={handleSeeDetailsClick}
				style={{ marginTop: "3px", marginBottom: "6px" }}
			>
				See Details
			</Button>
		);
	};

	const handleAddStoreButtonClick = useCallback(() => {
		if (query && query.get("gid")) {
			navigate(`/add-store?gid=${query.get("gid")}`);
		}
	}, [navigate, query]);

	useEffect(() => {
		getGroup();
	}, [getGroup]);
	return (
		<div className="group">
			{group &&
				Object.entries(group).map(([name, value], index) => {
					return (
						<div key={index}>
							{labels[name as keyof typeof labels] ? (
								<>
									<FormControl className="fullwidth" key={index}>
										<Grid container className="form-grid">
											<Grid item xl={2} lg={2} md={2} sm={6} xs={2} className="form-grid-label">
												<InputLabel
													shrink
													htmlFor="bootstrap-input"
													className="theme-input-label form-grid-label"
												>
													{labels[name as keyof typeof labels] + ":"}
												</InputLabel>
											</Grid>
											<Grid
												item
												xl={10}
												lg={10}
												md={10}
												sm={6}
												xs={6}
												className="form-grid-field"
											>
												<ThemeTextField
													name={name}
													value={value}
													onChange={() => null}
													style={{ width: 400 }}
												/>
											</Grid>
										</Grid>
									</FormControl>
									<div className="theme-section-margin" />
								</>
							) : null}
						</div>
					);
				})}
			<ActionButton onClick={handleAddStoreButtonClick}>
				<div>Add Store</div>
			</ActionButton>
			<div className="grid-header">Stores</div>
			<div className="theme-section-margin" />
			<div className="ag-grid-main-container">
				<div className="ag-theme-alpine" style={{ height: "500px", width: "100%" }}>
					<AgGridReact
						ref={agGridRef}
						rowData={group?.stores ? group.stores : []}
						onGridReady={handleAgGridReady}
						onFirstDataRendered={handleFirstDataRendered}
						animateRows
						paginationPageSize={6}
						defaultColDef={{
							filter: "agTextColumnFilter",
							floatingFilter: true,
							resizable: true
						}}
						enableCellChangeFlash={true}
						defaultColGroupDef={{ marryChildren: true }}
						frameworkComponents={{
							handleSeeDetailsCellRenderer: handleSeeDetailsCellRenderer,
							handleDeviceCountCellRenderer: handleDeviceCountCellRenderer
						}}
						rowSelection={"single"}
					>
						<AgGridColumn
							field="store_id"
							headerName="ID"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn>
						<AgGridColumn
							field="store_name"
							headerName="Name"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn>
						<AgGridColumn
							field="devices"
							headerName="Active Devices"
							cellRenderer="handleDeviceCountCellRenderer"
							suppressSizeToFit
							filter={false}
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn>
						<AgGridColumn
							field="city"
							headerName="City"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn>
						<AgGridColumn
							field="state_code"
							headerName="State"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn>
						<AgGridColumn
							field="pincode"
							headerName="Pincode"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn>

						<AgGridColumn
							headerName="Action"
							cellRenderer="handleSeeDetailsCellRenderer"
							suppressSizeToFit
							filter={false}
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn>
					</AgGridReact>
				</div>
			</div>
		</div>
	);
};

export default Group;
