/* eslint-disable */
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import "./customFilter.css";
const FILTER_FUNCTIONS = {
	contains: "contains",
	not_contains: "not_contains",
	equals: "equals",
	not_equals: "not_equals",
	starts_with: "starts_with",
	ends_with: "ends_with"
};

export default forwardRef((props, ref) => {
	const [filterText, setFilterText] = useState(null);
	const [filterType, setFilterType] = useState(FILTER_FUNCTIONS.starts_with);
	const [search, setSearch] = useState(false);
	const inputRef = useRef(null);
	const selectRef = useRef(null);
	const { schema, setPayloadSchema, functionToBePassed, setParentSchema } =
		props.frameworkComponentWrapper.agGridReact.props.frameworkComponents;

		console.log("===>schema", schema);

	const formatPart = (str, total_number) => {
		// console.log("===>str", str);
		const zeroNumber = total_number - str.length;
		let formattedString = "";
		for (let i = 0; i < zeroNumber; i++) {
			formattedString += "0";
		}
		formattedString += str;
		// console.log("===>formatted string", formattedString);
		return formattedString;
	};

	const handleFormatClaimNumber = () => {
		// Remove any non-numeric characters
		console.log("===>focus out");
		let value = filterText;
		if (value && props.colDef.field === "claim_number") {
			const matches = value.match(/-/g);
			const dashCount = matches ? matches.length : 0;
			console.log("====>dashcount", dashCount);
			for (let i = 0; i < 3 - dashCount; i++) {
				value += "-0";
			}
			const slicedArray = value.split("-");
			// console.log("===>sliceds array", slicedArray);
			let part1 = slicedArray[0];
			let part2 = slicedArray[1];
			let part3 = slicedArray[2];
			let part4 = slicedArray[3];

			part1 = formatPart(part1, 4);
			part2 = formatPart(part2, 3);
			part3 = formatPart(part3, 6);
			// console.log("===>new part 3", formatPart(part3));
			part4 = formatPart(part4, 4);
			const formattedString = `${part1}-${part2}-${part3}-${part4}`;
			// console.log("===>string", formattedString);
			setFilterText(formattedString);

			return formattedString;
		}

		return "";
	};

	useImperativeHandle(ref, () => {
		return {
			getValue() {
				return filterText;
			},
			myMethodForTakingValueFromFloatingFilter(value) {
				setFilterText(value);
			},

			handleSearch() {
				setSearch(true);
			},
			handleFormatClaimNumber() {
				return handleFormatClaimNumber();
			}
		};
	});

	const onInputBoxChanged = useDebouncedCallback(async (event) => {
		if (event.target.value) setFilterText(event.target.value);
		else setFilterText("");
	}, 3000);

	const onSelectChanged = (event) => {
		setFilterType(event.target.value);
	};

	useEffect(() => {
		if (filterText === null || props.column.colDef.field === null || filterType === null || !search) {
		} else {
			if (inputRef.current) {
				inputRef.current.value = filterText;
			}
			if (selectRef.current) {
				selectRef.current.value = filterType;
			}
			props.filterChangedCallback();
			setPayloadSchema({
				...schema,
				[props.column.colDef.field]: { value: filterText, type: filterType }
			});
			setSearch(false);
			//functionToBePassed();
		}
	}, [filterText, filterType, functionToBePassed, props, schema, setPayloadSchema, search]);

	console.log("===>formatted Value", filterText);

	return (
		<div className="custom-ag-icon">
			<div style={{ padding: "4px" }} className="customFilter">
				<div className="filter-container">
					<select
						className="customFilterInput"
						ref={selectRef}
						style={{ margin: "4px 0 4px 0", padding: "0px" }}
						onInput={onSelectChanged}
					>
						{/* <option value={FILTER_FUNCTIONS.contains}>Contains</option>
					<option value={FILTER_FUNCTIONS.not_contains}>Not contains</option>
					<option value={FILTER_FUNCTIONS.equals}>Equals</option>
					<option value={FILTER_FUNCTIONS.not_equals}>Not equals</option> */}
						<option value={FILTER_FUNCTIONS.starts_with} selected>
							Starts with
						</option>
						{/* <option value={FILTER_FUNCTIONS.ends_with}>Ends with</option> */}
					</select>
					<b></b>
					{/* <input
						className="customFilterInput"
						ref={inputRef}
						value={filterText}
						style={{ margin: "4px 0 4px 0" }}
						onInput={onInputBoxChanged}
						onFocus={handleFormatClaimNumber}
						placeholder="Filter..."
					/> */}
				</div>
			</div>
		</div>
	);
});
