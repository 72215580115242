import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AuthContext, UIContext } from "../../../../../../contexts";
import { useNavigate } from "react-router-dom";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { PaginationTypes } from "../../../../../../@types/pagination.types";
import { Button, Checkbox, CheckboxProps, useMediaQuery, withStyles } from "@material-ui/core";
import getLimit from "../../../../../../utils/pagination/getLimit";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../../constants/caption/dashboardHeader";
import getAgGridHeight from "../../../../../../utils/commonFunctions/getAgGridHeight";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { handleValueCellRenderer } from "../../../../../../utils/commonFunctions/cellRenderers";
import { CustomFilter, CustomFloatingFilter, CustomPagination } from "../../../../../shared";
import { dateTimeZoneConverter } from "../../../../../shared/cellRenderer/cellRenderers";
import initCapitalize from "../../../../../../utils/commonFunctions/initCapitalize";
import { green } from "@material-ui/core/colors";
import { User } from "../../../../../../@types/user.types";

const UserManagementGrid = ({
	groupOwnersList,
	handleChangeUserPermission
}: {
	groupOwnersList: User[];
	handleChangeUserPermission: (payload: any) => Promise<void>;
}) => {
	const navigate = useNavigate();
	const [gridHeight, setGridHeight] = useState("400px");
	const [customersFilterSchema, setcustomersFilterSchema] = useState({
		first_name: { value: null, type: null },
		last_name: { value: null, type: null },
		date_of_birth: { value: null, type: null },
		ssn: { value: null, type: null },
		role: { value: null, type: null },
		group_name: { value: null, type: null },
		group_number: { value: null, type: null },
		location: { value: null, type: null }
	});
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	// eslint-disable-next-line no-unused-vars
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);

	const GreenCheckbox = withStyles({
		root: {
			color: "#25a18e",
			"&$checked": {
				color: "#25a18e"
			}
		},
		onClick: {},
		checked: {}
	})((props: CheckboxProps) => <Checkbox color="default" {...props} size="small" />);

	const handleAgGridReady = (params: GridReadyEvent) => {
		// eslint-disable-next-line no-unused-vars
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};

	const handleGroupOwnerPermisssion = ({ data }: any) => {
		const payload = {
			group_owner_object_id: data._id,
			field: "is_group_and_store_permission",
			checked: !data.is_group_and_store_permission
		};
		const handleChangePermission = () => {
			handleChangeUserPermission(payload);
		};
		return <GreenCheckbox checked={data.is_group_and_store_permission} onChange={handleChangePermission} />;
	};

	const handleComponentPermisssion = ({ data }: any) => {
		const payload = {
			group_owner_object_id: data._id,
			field: "is_component_permission",
			checked: !data.is_component_permission
		};
		const handleChangePermission = () => {
			handleChangeUserPermission(payload);
		};
		return <GreenCheckbox onChange={handleChangePermission} checked={data.is_component_permission} />;
	};

	const handleDevicePermission = ({ data }: any) => {
		const payload = {
			group_owner_object_id: data._id,
			field: "is_device_management_permission",
			checked: !data.is_device_management_permission
		};
		const handleChangePermission = () => {
			handleChangeUserPermission(payload);
		};
		return <GreenCheckbox onChange={handleChangePermission} checked={data.is_device_management_permission} />;
	};

	const handleUserManagementPermisssion = ({ data }: any) => {
		const payload = {
			group_owner_object_id: data._id,
			field: "is_user_management_permission",
			checked: !data.is_user_management_permission
		};
		const handleChangePermission = () => {
			handleChangeUserPermission(payload);
		};
		return <GreenCheckbox onChange={handleChangePermission} checked={data.is_user_management_permission} />;
	};
	const handleProductManagementPermisssion = ({ data }: any) => {
		const payload = {
			group_owner_object_id: data._id,
			field: "is_product_management_permission",
			checked: !data.is_product_management_permission
		};
		const handleChangePermission = () => {
			handleChangeUserPermission(payload);
		};
		return <GreenCheckbox onChange={handleChangePermission} checked={data.is_product_management_permission} />;
	};
	const handleDiseaseManagementPermisssion = ({ data }: any) => {
		const payload = {
			group_owner_object_id: data._id,
			field: "is_disease_management_permission",
			checked: !data.is_disease_management_permission
		};
		const handleChangePermission = () => {
			handleChangeUserPermission(payload);
		};
		return <GreenCheckbox onChange={handleChangePermission} checked={data.is_disease_management_permission} />;
	};
	const handleFeaturePermisssion = ({ data }: any) => {
		const payload = {
			group_owner_object_id: data._id,
			field: "is_feature_permission",
			checked: !data.is_feature_permission
		};
		const handleChangePermission = () => {
			handleChangeUserPermission(payload);
		};
		return <GreenCheckbox onChange={handleChangePermission} checked={data.is_feature_permission} />;
	};

	const handlePhoneNumberPermission = ({ data }: any) => {
		const payload = {
			group_owner_object_id: data._id,
			field: "is_phone_number_visibility",
			checked: !data.is_phone_number_visibility
		};
		const handleChangePermission = () => {
			handleChangeUserPermission(payload);
		};
		return <GreenCheckbox onChange={handleChangePermission} checked={data.is_phone_number_visibility} />;
	};

	const handleDateCellRenderer = ({ value }: { value: string }) => dateTimeZoneConverter(value);

	const handleUserRoleCellRenderer = ({ value }: { value: string }) => {
		const role = initCapitalize(value);
		return <div>{role}</div>;
	};

	const handleRegisteredcustomerCellRenderer = ({ value }: { value: boolean }) => {
		return <div>{value ? "Yes" : "No"}</div>;
	};

	// const getcustomersCount = useCallback(async () => {
	// 	if (limit) {
	// 		const customerCount = await api.user.getcustomerCount();
	// 		const pageCount = Math.ceil(customerCount / limit);
	// 		console.log("pageCount", pageCount);
	// 		setPagination(Object.assign({}, pagination, { pageCount }));
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [limit]);

	// useEffect(() => {
	// 	if (limit && pagination.pageCount) {
	// 		getcustomers();
	// 	}
	// }, [pagination, limit, getcustomers]);

	// useEffect(() => {
	// 	getcustomers();
	// 	setDashboardHeader(ADMIN_DASHBOARD_HEADER.all_group_customers);
	// }, [getcustomers, setDashboardHeader]);

	useEffect(() => {
		const _gridHeight = getAgGridHeight(groupOwnersList.length);
		setGridHeight(_gridHeight);
	}, [groupOwnersList.length]);
	return (
		<div>
			<div className="employee-dependent-container">
				<div className="employee-dependent-grid-container">
					<div className="ag-theme-alpine" style={{ height: gridHeight, width: "100%" }}>
						<AgGridReact
							ref={agGridRef}
							rowData={groupOwnersList}
							onGridReady={handleAgGridReady}
							onFirstDataRendered={handleFirstDataRendered}
							animateRows
							paginationPageSize={6}
							defaultColDef={{
								filter: "agTextColumnFilter",
								floatingFilter: true,
								resizable: true
							}}
							enableCellChangeFlash={true}
							defaultColGroupDef={{ marryChildren: true }}
							frameworkComponents={{
								handleComponentPermisssion: handleComponentPermisssion,
								handleDevicePermission: handleDevicePermission,
								handleGroupOwnerPermisssion: handleGroupOwnerPermisssion,
								handleFeaturePermisssion: handleFeaturePermisssion,
								handleUserManagementPermisssion: handleUserManagementPermisssion,
								handleProductManagementPermisssion: handleProductManagementPermisssion,
								handleDiseaseManagementPermisssion: handleDiseaseManagementPermisssion,
								handlePhoneNumberPermission: handlePhoneNumberPermission,
								schema: customersFilterSchema,
								handleDateCellRenderer: handleDateCellRenderer,
								handleUserRoleCellRenderer: handleUserRoleCellRenderer,
								handleRegisteredcustomerCellRenderer: handleRegisteredcustomerCellRenderer,
								handleValueCellRenderer: handleValueCellRenderer,
								setPayloadSchema: setcustomersFilterSchema,
								customFloatingFilter: CustomFloatingFilter,
								customFilter: CustomFilter
							}}
							rowSelection={"single"}
						>
							<AgGridColumn
								field="first_name"
								headerName="First Name"
								suppressSizeToFit={true}
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
								floatingFilterComponent="customFloatingFilter"
								filter="customFilter"
								cellStyle={() => {
									return { cursor: "pointer" };
								}}
							></AgGridColumn>

							<AgGridColumn
								field="last_name"
								headerName="Last Name"
								suppressSizeToFit={true}
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
								floatingFilterComponent="customFloatingFilter"
								filter="customFilter"
								cellStyle={() => {
									return { cursor: "pointer" };
								}}
							></AgGridColumn>

							<AgGridColumn
								field="role"
								headerName="Role"
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
								floatingFilterComponent="customFloatingFilter"
								filter="customFilter"
								suppressSizeToFit={true}
								cellStyle={() => {
									return { cursor: "pointer" };
								}}
							></AgGridColumn>
							{/* <AgGridColumn
								field="group_name"
								headerName="Group Name"
								suppressSizeToFit
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
								floatingFilterComponent="customFloatingFilter"
								filter="customFilter"
								cellStyle={() => {
									return { cursor: "pointer" };
								}}
							></AgGridColumn> */}
							{/* <AgGridColumn
								field="store_name"
								headerName="Store Name"
								suppressSizeToFit
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
								floatingFilterComponent="customFloatingFilter"
								filter="customFilter"
								cellStyle={() => {
									return { cursor: "pointer" };
								}}
							></AgGridColumn> */}
							<AgGridColumn
								field="is_user_management_permission"
								headerName="User Management Permission"
								cellRenderer="handleUserManagementPermisssion"
								filter={false}
								suppressSizeToFit={true}
							></AgGridColumn>
							<AgGridColumn
								field="is_group_and_store_permission"
								headerName="Groups & Stores Permission"
								cellRenderer="handleGroupOwnerPermisssion"
								filter={false}
								suppressSizeToFit={true}
							></AgGridColumn>
							<AgGridColumn
								field="is_feature_permission"
								headerName="Feature Permission"
								cellRenderer="handleFeaturePermisssion"
								filter={false}
								suppressSizeToFit={true}
							></AgGridColumn>
							<AgGridColumn
								field="is_component_permission"
								headerName="Component Permission"
								cellRenderer="handleComponentPermisssion"
								filter={false}
								suppressSizeToFit={true}
							></AgGridColumn>
							<AgGridColumn
								field="is_device_management_permission"
								headerName="Device Permission"
								cellRenderer="handleDevicePermission"
								filter={false}
								suppressSizeToFit={true}
							></AgGridColumn>
							<AgGridColumn
								field="is_product_management_permission"
								headerName="Product Permission"
								cellRenderer="handleProductManagementPermisssion"
								filter={false}
								suppressSizeToFit={true}
							></AgGridColumn>
							<AgGridColumn
								field="is_disease_management_permission"
								headerName="Disease Permission"
								cellRenderer="handleDiseaseManagementPermisssion"
								filter={false}
								suppressSizeToFit={true}
							></AgGridColumn>
							<AgGridColumn
								field="is_phone_number_visibility"
								headerName="Phone Number Visibility"
								cellRenderer="handlePhoneNumberPermission"
								filter={false}
								suppressSizeToFit={true}
							></AgGridColumn>
						</AgGridReact>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserManagementGrid;
