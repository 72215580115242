import { Pagination } from "@material-ui/lab";

// eslint-disable-next-line react/prop-types
const CustomPagination = ({ pageCount, currentPage, handlePageChange }) => {
	console.log("page page", currentPage);
	return (
		<div className="custom-pagination-outer-container" id="custom-pagination-outer-container">
			<div className="custom-pagination-inner-container">
				<Pagination
					className="pagination"
					count={pageCount}
					defaultPage={1}
					page={currentPage}
					boundaryCount={2}
					onChange={handlePageChange}
					showFirstButton
					showLastButton
				/>
			</div>
		</div>
	);
};

export default CustomPagination;
