/* eslint-disable */

import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import "./customFloatingFilter.css";

export default forwardRef((props, ref) => {
	const inputRef = useRef(null);
	const [value, setValue] = useState("");
	useImperativeHandle(ref, () => {
		return {
			onParentModelChanged(parentModel) {
				// inputRef.current.value = parentModel;
			}
		};
	});

	const onInputBoxChanged = async (input) => {
		console.log("====>target value", input.target.value);
		props.parentFilterInstance((instance) => {
			setTimeout(() => {
				instance?.componentInstance?.myMethodForTakingValueFromFloatingFilter(input.target.value);
			}, 100);
		});
	};

	const onBlur = () => {
		if (props.column.colDef.field === "claim_number") {
			props.parentFilterInstance((instance) => {
				const value = instance?.componentInstance?.handleFormatClaimNumber();
				console.log("====value after blur", value);
				inputRef.current.value = value ? value : "";
			});
		}
	};

	const handleSearchButtonClick = () => {
		props.parentFilterInstance((instance) => {
			instance?.componentInstance?.handleSearch();
		});
	};

	const handleHitEnter = (event) => {
		if (event.key === "Enter") {
			props.parentFilterInstance((instance) => {
				instance?.componentInstance?.handleSearch();
			});
		}
	};

	// const getValue = () => {
	// 	props.parentFilterInstance((instance) => {
	// 		const text = instance?.componentInstance?.getValue();
	// 		inputRef.current.value = text;
	// 		return text;
	// 	});
	// };

	const style = {
		color: props.color
	};

	console.log("input value", inputRef);
	return (
		<div className="inputWithSearchFilter">
			<input
				ref={inputRef}
				className="customFloatingFilter"
				style={style}
				type="text"
				onInput={onInputBoxChanged}
				onBlur={onBlur}
				onKeyDown={handleHitEnter}
			/>
			<button onClick={handleSearchButtonClick} className="searchButton">
				<span>
					<SearchIcon style={{ fontSize: "18px" }} />
				</span>
			</button>
		</div>
	);
});
