import { useState, useContext, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton } from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { CellValueChangedEvent, ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { dateTimeZoneConverter } from "../../../../../../utils/commonFunctions/date";
import initCapitalize from "../../../../../../utils/commonFunctions/initCapitalize";
import useRouteParam from "../../../../../../hooks/useRouteParam";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { IFootcareDevice } from "../../../../../../@types/device.interface";

import CreateIcon from "@material-ui/icons/Create";
import useQuerySearch from "../../../../../../hooks/querySearch/useQuerySearch";

const DeviceManagementGrid = ({ gridData }: { gridData: IFootcareDevice[] }) => {
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const groupNumber = useQuerySearch("gn");
	const agGridRef = useRef<any>(null);
	const navigate = useNavigate();

	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(api);
		setAgGridColumnAPI(columnApi);
		api.sizeColumnsToFit();
	};
	const handleFirstDataRendered = (params: any) => null;

	const handleDateCellRenderer = ({ value }: { value: any }) => {
		const date = dateTimeZoneConverter(value as string) as string;
		return <div>{value ? date : "N/A"}</div>;
	};

	const handleUserRoleCellRenderer = ({ value }: { value: string }) => {
		const role = initCapitalize(value);
		return <div>{role}</div>;
	};

	const routeToEdit = (device_id: string) => {
		let path = `/settings/edit-device/${device_id}`;
		if (groupNumber) {
			path = `/admin/settings/edit-device/${device_id}?gn=${groupNumber}`;
		}
		navigate(path);
	};

	const handleActionCellRenderer = (rowData: any) => {
		const { data } = rowData;
		return (
			<IconButton onClick={() => routeToEdit(data.device_id)}>
				<CreateIcon />
			</IconButton>
		);
	};

	const handleRegisteredCellRender = ({ value }: { value: boolean }) => {
		return <div>{value ? "Yes" : "No"}</div>;
	};

	const handleApprovedByCellRender = ({ value }: { value: any }) => {
		return <div>{typeof value === typeof {} ? value.user_name : value}</div>;
	};

	return (
		<div className="user-management-grid-container glb-tab-grid-container">
			<div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
				<AgGridReact
					ref={agGridRef}
					rowData={gridData}
					onGridReady={handleAgGridReady}
					onFirstDataRendered={handleFirstDataRendered}
					animateRows
					rowDragManaged
					frameworkComponents={{
						handleActionCellRenderer: handleActionCellRenderer,
						handleRegisteredCellRender: handleRegisteredCellRender,
						handleUserRoleCellRenderer: handleUserRoleCellRenderer,
						handleDateCellRenderer: handleDateCellRenderer
					}}
					defaultColDef={{
						filter: "agTextColumnFilter",
						floatingFilter: true,
						resizable: true,
						floatingFilterComponentParams: { suppressFilterButton: true }
					}}
					defaultColGroupDef={{ marryChildren: true }}
					rowSelection="single"
					enableCellChangeFlash={true}
				>
					<AgGridColumn field="device_id" headerName="Device Id"></AgGridColumn>
					<AgGridColumn
						field="group-details.group_name"
						headerName="Group Name"
						suppressSizeToFit={true}
					></AgGridColumn>
					<AgGridColumn field="store-details.store_name" headerName="Store Name"></AgGridColumn>
					<AgGridColumn
						field="createdAt"
						headerName="Assigned Date"
						cellRenderer="handleDateCellRenderer"
					></AgGridColumn>
					<AgGridColumn
						field="action"
						headerName="Edit"
						cellRenderer="handleActionCellRenderer"
						filter={false}
					></AgGridColumn>
				</AgGridReact>
			</div>
		</div>
	);
};

export default DeviceManagementGrid;
