import React, { useCallback, useContext, useEffect, useState } from "react";
import UserManagementGrid from "./userManagementGrid/UserManagementGrid";
import { CustomPagination } from "../../../../shared";
import { trackPromise } from "react-promise-tracker";
import { User } from "../../../../../@types/user.types";
import { PaginationQuery, PaginationTypes } from "../../../../../@types/pagination.types";
import { api } from "../../../../../utils/api";
import { Button, useMediaQuery } from "@material-ui/core";
import getLimit from "../../../../../utils/pagination/getLimit";
import { AuthContext, UIContext } from "../../../../../contexts";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../constants/caption/dashboardHeader";
import { useNavigate } from "react-router-dom";

const UserManagement = () => {
	const navigate = useNavigate();
	const { user, setUser } = useContext(AuthContext);
	const [groupOwners, setGroupOwners] = useState<User[]>([]);
	const [limit, setLimit] = useState<number | null>(null);
	const [detailsChange, setDetailsChange] = useState<boolean>(false);
	const [pagination, setPagination] = useState<PaginationTypes>({
		currentPage: 1,
		pageCount: undefined
	});
	const { currentPage, pageCount } = pagination;
	const { setDashboardHeader } = useContext(UIContext);
	const xl = useMediaQuery("(min-width:1600px)");
	const lg = useMediaQuery("(min-width:1200px)");
	const md = useMediaQuery("(min-width:800px)");
	const sm = useMediaQuery("(min-width:600px)");
	const xs = useMediaQuery("(min-width:400px)");
	const _limit = getLimit(xl, lg, md, sm, xs);

	const handlePageChange = useCallback(
		(event: React.ChangeEvent<unknown>, page: number) => {
			setPagination(
				Object.assign({}, pagination, {
					currentPage: page
				})
			);
		},
		[pagination]
	);

	const handleChangeUserPermission = useCallback(
		async (payload: any) => {
			const response = await trackPromise(api.userManagement.updateGroupOwnersPermission(payload));
			if (response) {
				// eslint-disable-next-line arrow-parens
				setDetailsChange((prevDetails) => !prevDetails);
				console.log("user", user?._id);
				console.log("response", response._id);
				if (response._id === user?._id) {
					console.log("changed");
					setUser(response);
				}
			}
		},
		[setUser, user?._id]
	);

	const getPaginatedGroupOwnerList = useCallback(async () => {
		if (typeof limit === "number" && typeof pagination.currentPage === "number") {
			const paginationQuery: PaginationQuery = {
				limit: limit,
				page: pagination.currentPage,
				role: user?.role
			};
			const groupList = await trackPromise(api.userManagement.getPaginatedGroupOwnerList(paginationQuery));
			setGroupOwners(groupList.group_owners);
			setPagination(
				Object.assign({}, pagination, {
					pageCount: groupList.totalPages
				})
			);
		}
	}, [limit, pagination.currentPage]);

	const handleAddUserButtonClick = () => {
		const pathName = "/settings/add-user";
		navigate(pathName);
	};

	useEffect(() => {
		// getcustomersCount();
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.user_management);
	}, [setDashboardHeader]);

	useEffect(() => {
		if (_limit >= 0) {
			setLimit(_limit);
			getPaginatedGroupOwnerList();
		}
	}, [_limit, getPaginatedGroupOwnerList, detailsChange]);

	return (
		<div>
			<div className="pf-action-button-container" id="pf-action-button-container">
				<Button
					className="button-green"
					variant="contained"
					onClick={handleAddUserButtonClick}
					style={{
						cursor: "pointer",
						pointerEvents: "unset"
					}}
				>
					<span className="button-label-with-icon">Add User</span>
					<span>
						<GroupAddIcon className="button-icon" />
					</span>
				</Button>
			</div>
			<UserManagementGrid groupOwnersList={groupOwners} handleChangeUserPermission={handleChangeUserPermission} />
			<div className="custom-pagination" id="custom-pagination">
				<CustomPagination pageCount={pageCount} currentPage={currentPage} handlePageChange={handlePageChange} />
			</div>
		</div>
	);
};

export default UserManagement;
