import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement);

type BarChartProps = {
	data: any;
	options: any;
};

const BarChart: React.FC<BarChartProps> = ({ data, options }) => {
	const datasets: any[] = [
		{
			label: "HP",
			backgroundColor: "#F08080"
		}
	];
	// const data = {
	// 	labels: ["HP", "CC", "GSTD", "GLTD 1", "GLTD 2", "HOO", "KSOP", "GLI", "CCE"],
	// 	datasets: [
	// 		{
	// 			// label: ["HP", "CC", "GSTD", "GLTD 1", "GLTD 2", "HOO", "KSOP", "GLI", "CCE"],

	// 			data: [400, 500, 800, 200, 90, 200, 30, 700, 100], // Replace these values with your actual data
	// 			backgroundColor: [
	// 				"#F08080",
	// 				"#F28C87",
	// 				"#F59D92",
	// 				"#F6A296",
	// 				"#F8AD9D",
	// 				"#FAB9A4",
	// 				"#FBC4AB",
	// 				"#FDCFB2",
	// 				"#FFDAB9"
	// 			],
	// 			borderColor: [
	// 				"#F08080",
	// 				"#F28C87",
	// 				"#F59D92",
	// 				"#F6A296",
	// 				"#F8AD9D",
	// 				"#FAB9A4",
	// 				"#FBC4AB",
	// 				"#FDCFB2",
	// 				"#FFDAB9"
	// 			],
	// 			borderWidth: 1
	// 		}
	// 	]
	// };

	// const options = {
	// 	scales: {
	// 		x: {
	// 			type: "category",
	// 			labels: ["HP", "CC", "GSTD", "GLTD 1", "GLTD 2", "HOO", "KSOP", "GLI", "CCE"]
	// 		},
	// 		y: {
	// 			beginAtZero: true
	// 		}
	// 	},
	// 	plugins: {
	// 		legend: {
	// 			display: false,
	// 			position: "bottom",
	// 			labels: {
	// 				color: "#000" // Set the color you want for the legend labels
	// 			},
	// 			generateLabels: (datasets: any) =>
	// 				datasets.map((dataset: any) => ({
	// 					text: dataset.label,
	// 					fillStyle: dataset.backgroundColor,
	// 					hidden: dataset.hidden,
	// 					lineCap: "round",
	// 					lineDash: [],
	// 					lineDashOffset: 0,
	// 					lineJoin: "round",
	// 					lineWidth: 1,
	// 					strokeStyle: dataset.borderColor,
	// 					pointStyle: "circle",
	// 					datasetIndex: dataset.index
	// 				}))
	// 		}
	// 	}
	// };

	return (
		<div style={{ width: "100%", height: "100%", fontSize: "1rem" }}>
			{/* <h2>New Bar Chart</h2> */}
			<Bar data={data} options={options as unknown as any} />
		</div>
	);
};

export default BarChart;
