import { FC, useCallback, useState } from "react";
import { Logo } from "../../../../assets";
import { FormControl, InputLabel, Paper } from "@material-ui/core";
import ThemeTextField from "../../../shared/theme/textField/ThemeTextField";
import ThemeButton from "../../../shared/theme/button/Button";
import { AiOutlineLogin } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgetUsername: FC = (): JSX.Element => {
	const [email, setEmail] = useState("");
	const navigate = useNavigate();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleSubmit = useCallback(async () => {
		try {
			const response = await axios.post("http://localhost:4008/api/v1/auth/forget-user-name", {
				role: "ADMIN",
				email
			});
			if (response) {
				alert("Username Sent to email");
				navigate("/login");
			}
		} catch (err) {
			alert("Sorry! No user found");
		}
	}, [email, navigate]);

	return (
		<div className="forget-username auth">
			<div className="auth-container">
				<div className="logo-container">
					<img src={Logo} className="auth-img" />
				</div>
				<Paper elevation={3} className="auth-paper-container">
					<div className="auth-text theme-primary-color">Forget Username?</div>
					<div className="theme-section-margin" />
					<FormControl className="fullwidth">
						<InputLabel shrink htmlFor="bootstrap-input" className="theme-input-label">
							Email
						</InputLabel>
						<ThemeTextField value={email} onChange={handleChange} />
					</FormControl>
					<div className="theme-section-margin" />
					<ThemeButton onClick={handleSubmit}>
						Submit{" "}
						<span className="button-icon">
							<AiOutlineLogin />
						</span>
					</ThemeButton>
				</Paper>
			</div>
		</div>
	);
};

export default ForgetUsername;
