/* eslint-disable react-hooks/exhaustive-deps */
import initialState from "./store";
import actions from "./actions";
import reducer from "./reducer";
import { useReducer, useCallback } from "react";
import { ContextProviderProps } from "../../@types/contexts/context.types";
import { SelectedTab } from "../../@types/contexts/UIContext/store.types";
import UIContext from "./UIContext";

const UIContextProvider = ({ children }: ContextProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {
		dashboardHeader: state.dashboardHeader,
		selectedTab: state.selectedTab,
		setDashboardHeader: useCallback((dashboardHeader: string) => {
			dispatch({ type: actions.SET_DASHBOARD_HEADER, payload: { ...state, dashboardHeader } });
		}, []),
		setSelectedTab: useCallback((selectedTab: SelectedTab) => {
			dispatch({ type: actions.SET_SELETED_TAB, payload: { ...state, selectedTab } });
		}, []),
		customer: state.customer,
		setCustomerUI: useCallback(
			(customer: {
				customer_id: string;
				store_name: string;
				scan_id: string;
				device_id: string;
				first_name: string;
				last_name: string;
				date: string;
				phone: string;
				gender: string;
			}) => {
				dispatch({ type: actions.SET_customer, payload: { ...state, customer } });
			},
			[]
		)
	};

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export default UIContextProvider;
