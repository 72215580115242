/* eslint-disable prettier/prettier */
import { useState, useEffect, useContext, useCallback, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Tab, Tabs, Box } from "@material-ui/core";
import DeviceManagementGrid from "./deviceManagementGrid/DeviceManagementGrid";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../constants/caption/dashboardHeader";
import { AuthContext, UIContext } from "../../../../../contexts";
// import { api } from "../../../../utils/api";

import TabPanel from "../../../../shared/tabPanelComponent/TabPanel";
import a11yProps from "../../../../../constants/tabPanelProps/ayProps";
import DevicesIcon from "@material-ui/icons/Devices";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "./deviceManagement.css";
import "../../../../../globalStyles/theme.css";
import { IFootcareDevice } from "../../../../../@types/device.interface";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../../../../utils/api";
import useQuerySearch from "../../../../../hooks/querySearch/useQuerySearch";

const DeviceManagement = () => {
	const [assignedDevice, setAssignedDevice] = useState<IFootcareDevice[]>([]);
	const [unAssignedDevice, setUnAssignedDevice] = useState<IFootcareDevice[]>([]);
	const [value, setValue] = useState<number>(0);
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const { setDashboardHeader, setSelectedTab } = useContext(UIContext);
	const groupNumber = useQuerySearch("gn");
	const storeNumber = useQuerySearch("sn");

	const handleLabelChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setValue(value);
	};

	const handleAddDeviceButtonClick = () => {
		const pathname = "/settings/add-device";
		navigate(pathname);
	};

	const handlegetAlldDevice = useCallback(async () => {
		let allDeviceList = [];
		if (storeNumber) {
			console.log("===>called this one");
			allDeviceList = await trackPromise(
				api.deviceManagement.getDevice({ group_number: groupNumber, store_number: storeNumber })
			);
		} else {
			if (groupNumber) {
				allDeviceList = await trackPromise(api.deviceManagement.getDevice({ group_number: groupNumber }));
			} else {
				allDeviceList = await trackPromise(api.deviceManagement.getDevice({}));
			}
		}
		if (allDeviceList) {
			const tempArrAssignedDevice = [];
			const tempArrUnAssignedDevice = [];
			for (const device of allDeviceList) {
				if (device.is_assigned) {
					tempArrAssignedDevice.push(device);
				} else {
					tempArrUnAssignedDevice.push(device);
				}
			}
			setAssignedDevice(tempArrAssignedDevice);
			setUnAssignedDevice(tempArrUnAssignedDevice);
		}
	}, [groupNumber, storeNumber]);

	useEffect(() => {
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.devices);
	}, [setDashboardHeader]);

	useEffect(() => {
		handlegetAlldDevice();
	}, [handlegetAlldDevice]);

	return (
		<div id="user-approval" className="user-approval">
			{groupNumber ? null : (
				<div className="pf-action-button-container" id="pf-action-button-container">
					<Button
						className="button-green"
						onClick={handleAddDeviceButtonClick}
						variant="contained"
						style={{
							cursor: "pointer",
							pointerEvents: "unset"
						}}
					>
						<span className="button-label-with-icon">Add Device</span>
						<span>
							<DevicesIcon className="button-icon" />
						</span>
					</Button>
				</div>
			)}
			<div className="user-management-tab-panel-container">
				<Box sx={{ width: "100%" }}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs
							value={value}
							onChange={handleLabelChange}
							aria-label="basic tabs example"
							TabIndicatorProps={{
								style: {
									border: "none",
									backgroundColor: "#25a18e"
								}
							}}
							className="glb-tab-panel"
						>
							<Tab label={"Assigned Device"} className="glb-tab-label" {...a11yProps(0)} />
							<Tab label={"Unassigned Device"} className="glb-tab-label" {...a11yProps(1)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<Suspense fallback={<div />}>
							<DeviceManagementGrid gridData={assignedDevice} />
						</Suspense>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Suspense fallback={<div />}>
							<DeviceManagementGrid gridData={unAssignedDevice} />
						</Suspense>
					</TabPanel>
				</Box>
			</div>
			{/* <div className="custom-pagination" id="custom-pagination">
				<CustomPagination pageCount={pageCount} currentPage={currentPage} handlePageChange={handlePageChange} />
			</div> */}
		</div>
	);
};

export default DeviceManagement;
