import { Grid, Paper } from "@material-ui/core";
import "./PainPointDetails.css";
import LeftPainPointDetails from "./leftPainPointDetails/LeftPainPointDetails";
import RightPainPointDetails from "./rightPainPointDetails/RightPainPointDetails";

const PainPointDetails = ({
	left_disease,
	right_disease,
	left_feet_pain_point,
	right_feet_pain_point,
	left_diease_details,
	right_diease_details,
	mode
}: any) => {
	console.log("====>left disease", left_diease_details);
	return (
		<Paper className="painPointDetails" style={{ marginBottom: "2.5rem", paddingTop: "12px" }}>
			<h2>Pain Points and Disease</h2>
			<Grid container spacing={2} style={{ padding: "1rem" }}>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<LeftPainPointDetails
						disease={left_disease}
						pain_point={left_feet_pain_point}
						disease_details={left_diease_details}
						mode={mode}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<RightPainPointDetails
						disease={right_disease}
						pain_point={right_feet_pain_point}
						disease_details={right_diease_details}
						mode={mode}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default PainPointDetails;
