import { forwardRef, JSXElementConstructor, ReactElement } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	IconButton
} from "@material-ui/core";
import { CustomDialogProps } from "../../../../@types/components/dialogProps.types";

import DoneIcon from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";

import "./customDialog.css";

const Transition: any = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = ({ dialogProps, handleClose }: CustomDialogProps): JSX.Element => {
	const { openDialog, dialogName, title, content, actions } = dialogProps;

	return (
		<div>
			<Dialog
				style={{ zIndex: 5000 }}
				open={openDialog}
				onClose={handleClose}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">
					{dialogName ? (
						<div className="custom-dialog-title-with-icon-container">
							<span>{title}</span>
							<span>
								<IconButton aria-label="close" onClick={handleClose}>
									<CloseIcon fontSize="medium" />
								</IconButton>
							</span>
						</div>
					) : (
						<div className="custom-dialog-title">{title}</div>
					)}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						<div className="custom-dialog-content">{content}</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions className="custom-dialog-action-button-container">
					{actions.map((action, index) => {
						const { label, callback, name } = action;
						return (
							<Button
								className={
									name
										? name === "submit" ||
										  name === "accept" ||
										  name === "add_employment" ||
										  name === "confirm"
											? "custom-dialog-action-button-green"
											: name === "reject"
											? "custom-dialog-action-button-red"
											: "custom-dialog-action-button-grey"
										: "custom-dialog-action-button"
								}
								variant="contained"
								key={index}
								onClick={callback}
							>
								{name ? (
									<>
										<span className="custom-dialog-action-button-label-with-icon">{label}</span>
										<span>
											{name === "submit" || name === "accept" ? (
												<DoneIcon className="custom-dialog-action-button-icon" />
											) : name === "add_employment" ? (
												<MonetizationOnIcon className="custom-dialog-action-button-icon" />
											) : name === "confirm" ? (
												<ThumbUpIcon className="custom-dialog-action-button-icon" />
											) : (
												<Close className="custom-dialog-action-button-icon" />
											)}
										</span>
									</>
								) : (
									label
								)}
							</Button>
						);
					})}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CustomDialog;
