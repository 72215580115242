import { ICard } from "../../../../@types/card.interface";
import DevicesIcon from "@material-ui/icons/Devices";
import BusinessIcon from "@material-ui/icons/Business";
import StoreIcon from "@material-ui/icons/Store";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ScanDetails from "./scanDetails/ScanDetails";
import CustomerDetails from "./customerDetails/CustomerDetails";
import { useState } from "react";
import AdminTabSection from "./adminTabSection/AdminTabSection";
import "./AdminDashboard.css";
import useGetDashboardData from "../../../../utils/hooks/useGetDashboardData/useGetDashboardData";

const SuperAdminDashboard = () => {
	const { dashboardData } = useGetDashboardData();

	const storeScanData = {
		labels: dashboardData?.storeReports.map((report: any) => report.store_name) || [],
		datasets: [
			{
				data: dashboardData?.storeReports.map((report: any) => report.scan_count) || [],
				backgroundColor: [
					"#8c4ccd",
					"#9960d2",
					"#a06cd5",
					"#a674d8",
					"#b388dd",
					"#bf9ce3",
					"#ccafe9",
					"#d9c3ee",
					"#e6d7f4"
				],
				borderColor: [
					"#8c4ccd",
					"#9960d2",
					"#a06cd5",
					"#a674d8",
					"#b388dd",
					"#bf9ce3",
					"#ccafe9",
					"#d9c3ee",
					"#e6d7f4"
				],
				borderWidth: 1,
				barThickness: dashboardData?.storeReports.length === 1 ? 50 : undefined, // Set fixed width if only one store
				maxBarThickness: 50 // Set max width for each bar
			}
		]
	};

	const scanDetailsOptions = {
		scales: {
			x: {
				type: "category",
				labels: dashboardData?.storeReports.map((report: any) => report.store_name),
				ticks: {
					font: {
						size: 12 // Adjust the font size for x-axis labels
					}
				}
			},
			y: {
				beginAtZero: true,
				ticks: {
					font: {
						size: 12 // Adjust the font size for x-axis labels
					}
				}
			}
		},
		plugins: {
			legend: {
				display: false,
				position: "bottom",
				labels: {
					color: "#000" // Set the color you want for the legend labels
				},
				generateLabels: (datasets: any) =>
					datasets.map((dataset: any) => ({
						text: dataset.label,
						fillStyle: dataset.backgroundColor,
						hidden: dataset.hidden,
						lineCap: "round",
						lineDash: [],
						lineDashOffset: 0,
						lineJoin: "round",
						lineWidth: 1,
						strokeStyle: dataset.borderColor,
						pointStyle: "circle",
						datasetIndex: dataset.index
					}))
			}
		}
	};
	return (
		<div>
			<div className="card-list">
				{/* {cardData.map((data, key) => {
					return <CardWithStat key={key} data={data.data} title={data.title} icon={data.icon} />;
				})} */}
				<ScanDetails
					cardUIProps={{
						cardColor: "#f2f2f2",
						cardTitle: "Today Total Scan Reports",
						overallTotal: "60000",
						marginTopText: "0.7rem"
					}}
					chartProps={{
						data: storeScanData,
						options: scanDetailsOptions
					}}
					// chart={barChart}
					key={0}
				/>
				{dashboardData?.customerCount ? <CustomerDetails data={dashboardData?.customerCount} /> : null}
			</div>
			{/* <h2></h2>
			<AdminTabSection /> */}
		</div>
	);
};

export default SuperAdminDashboard;
