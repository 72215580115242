import { forwardRef, ReactElement } from "react";
import { Dialog, DialogContent, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { ComponentType } from "react";
import { CustomImageDialogProps } from "../../../../@types/components/dialogProps.types";

const Transition: ComponentType<TransitionProps & { children?: ReactElement<any, any> | undefined }> = forwardRef(
	function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	}
);

const CustomImageDialog = ({ dialogProps: { openDialog, image, handleCloseCallback } }: CustomImageDialogProps) => {
	return (
		<Dialog
			fullScreen={false}
			style={{ zIndex: 60000 }}
			open={openDialog}
			TransitionComponent={Transition}
			keepMounted
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			onClose={handleCloseCallback}
			maxWidth="md"
		>
			<DialogContent>
				<img src={image} width="100%" height="100%" alt="health" />
			</DialogContent>
		</Dialog>
	);
};

export default CustomImageDialog;
