import { Switch } from "@material-ui/core";
import React, { useState } from "react";
import { api } from "../../../../../../../utils/api";
import { IProduct } from "../../../../../../../@types/product.types";

const SwitchCellRenderer = ({ data }: { data: IProduct }) => {
	const [isActive, setIsActive] = useState<boolean>(data.is_active);
	const handleChange = async () => {
		const response = await api.product.editProductById(data._id, { is_active: !isActive });
		if (response) {
			setIsActive(!isActive);
		}
	};
	return (
		<div>
			<Switch
				checked={isActive}
				onChange={handleChange}
				color="primary"
				name="checkedB"
				inputProps={{ "aria-label": "primary checkbox" }}
			/>
		</div>
	);
};

export default SwitchCellRenderer;
