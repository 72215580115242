/* eslint-disable prettier/prettier */
import React, { useState, useContext, useRef, useCallback, Suspense, useEffect } from "react";
import {
	AppBar,
	CssBaseline,
	Divider,
	Drawer,
	Hidden,
	Modal,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Toolbar,
	Avatar,
	Badge,
	Menu,
	MenuItem,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	makeStyles,
	useTheme,
	withStyles,
	Popover,
	Chip,
	Tooltip
} from "@material-ui/core";
import { Outlet, useNavigate } from "react-router-dom";
import { DialogProps } from "../../../../@types/dialogProps.types";

import { trackPromise } from "react-promise-tracker";

import { UIContext, AuthContext } from "../../../../contexts";

import { loader } from "../../../../utils/commonFunctions/loader";
// import { LazyCustomDialog } from "../../../shared";
import SIDEBAR_OPTIONS from "../../../../constants/sidebarOptions";
import { Tab } from "../../../../@types/sidebarOptions.types";
import { SidebarProps } from "../../../../@types/components/sidebar.types";
import { Logo, curaFootLogoWhite } from "../../../../assets"; // CuraFoot Logo

import MailIcon from "@material-ui/icons/Mail";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
// import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreIcon from "@material-ui/icons/MoreVert";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { FormControl, Select } from "@material-ui/core";
// import DropDown from "../../../shared/dropDown/DropDown";
import initCapitalize from "../../../../utils/commonFunctions/initCapitalize";
import ROLES from "../../../../constants/roles";

import "./superAdminSidebar.css";
import { LazyCustomDialog } from "../../../shared";

const drawerWidth = 240;

// eslint-disable-next-line arrow-parens
const useStyles = makeStyles((theme) => ({
	application: {
		display: "flex"
	},
	drawer: {},
	appBar: {},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			// display: "",
			backgroundColor: "#EEEEEE",
			color: "black"
		}
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {},
	content: {
		flexGrow: 1,
		minHeight: "100vh",
		padding: theme.spacing(3)
	},
	avatarColor: {
		color: "black",
		padding: 40
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex"
		}
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	},
	grow: {
		flexGrow: 1
	}
}));

// eslint-disable-next-line arrow-parens
const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: "#25a18e",
		color: "#25a18e",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "$ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			// eslint-disable-next-line quotes
			content: '""'
		}
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0
		}
	}
}))(Badge);

const SuperAdminSidebar = (): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const { user, setUser } = useContext(AuthContext);
	// const [user, setUser] = useState({
	// 	role: "SUPER ADMIN"
	// });
	const [drawerWidth, setDrawerWidth] = useState(240);
	const [mobileOpen, setMobileOpen] = useState<boolean>(true);

	const [limit, setLimit] = useState<number>();
	const [anchorEl, setAnchorEl] = useState<(EventTarget & (HTMLLIElement | HTMLButtonElement)) | null>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
	const [popOverAnchorEl, setPopOverAnchorEl] = useState<(EventTarget & SVGSVGElement) | null>(null);
	const { dashboardHeader, selectedTab, setDashboardHeader } = useContext(UIContext);

	// notification sound alert
	const [logoutDialogProps, setLogoutDialogProps] = useState<DialogProps>({
		openDialog: false,
		title: "",
		content: "",
		actions: []
	});
	const navigate = useNavigate();
	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const isPopOverOpen = Boolean(popOverAnchorEl);
	const id = isPopOverOpen ? "simple-popover" : undefined;
	const [pageNumber, setPageNumber] = useState<number>(0);
	const [skeletonLoad, setSkeletonLoad] = useState<boolean>(false);
	const loading = useRef<boolean>(false);

	const scrollToEnd = useCallback(() => {
		if (loading.current === false) {
			setPageNumber(pageNumber + 1);
		}
	}, [pageNumber]);

	const handleLogoutClick = useCallback(() => {
		localStorage.clear();
		setLogoutDialogProps(
			Object.assign({}, logoutDialogProps, {
				openDialog: true,
				title: "Thank you",
				content: "You have successfully logged out of CuraFoot CRM!",
				actions: [
					{
						label: "Okay",
						callback: () => {
							// window.location.reload();
							navigate("/login");
							setLogoutDialogProps(Object.assign({}, logoutDialogProps, { openDialog: false }));
						}
					}
				]
			})
		);
	}, [logoutDialogProps, navigate]);

	const scrollEvent = useCallback(
		(event: React.UIEvent<HTMLDivElement>) => {
			const { scrollHeight, scrollTop, clientHeight } = event.target as HTMLDivElement;

			if (scrollTop + clientHeight > scrollHeight - 5) {
				scrollToEnd();
			}
		},
		[scrollToEnd]
	);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
		const { currentTarget } = event;
		setAnchorEl(currentTarget);
		const menuArrowElement = document.getElementById("menuArrow");
		if (menuArrowElement) {
			menuArrowElement.style.display = "block";
		}
	};

	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

	const handleMenuClose = () => {
		setAnchorEl(null);
		const menuArrowElement = document.getElementById("menuArrow");
		if (menuArrowElement) {
			menuArrowElement.style.display = "none";
		}
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { currentTarget } = event;
		setMobileMoreAnchorEl(currentTarget);
	};

	const handleDrawerToggle = useCallback(() => {
		setMobileOpen(!mobileOpen);
		if (mobileOpen === true) {
			setDrawerWidth(0);
		} else {
			setDrawerWidth(240);
		}
	}, [mobileOpen]);

	const handleProfileClick = useCallback(() => {
		navigate("/my-profile");
		setAnchorEl(null);
		handleMobileMenuClose();
	}, [navigate]);

	const handleNameClick = useCallback(() => {
		navigate("/my-profile");
	}, [navigate]);

	const menuId = "primary-search-account-menu";

	const renderMenu = (
		<Popover
			id={menuId}
			open={isMenuOpen}
			anchorEl={anchorEl}
			onClose={handleMenuClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center"
			}}
			style={
				{
					//scrollbar: 5
				}
			}
		>
			<div className="profile-menu ">
				<List>
					<ListItem button onClick={handleProfileClick}>
						<ListItemAvatar>
							<Avatar className="list-avatar">
								<PersonIcon /* style={{ color: "#44b700" }} */ />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="My Profile" />
					</ListItem>
					<ListItem button onClick={handleLogoutClick}>
						<ListItemAvatar>
							<Avatar className="list-avatar">
								<ExitToAppIcon /* style={{ color: "#44b700"}} */ />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="Logout" />
					</ListItem>
				</List>
			</div>
		</Popover>
	);

	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<div className="sec-desk">
				<MenuItem onClick={handleProfileMenuOpen}>
					<IconButton
						aria-label="account of current user"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						color="inherit"
					>
						<AccountCircle style={{ color: "#4e4e4e" }} />
					</IconButton>
					<p>Profile</p>
				</MenuItem>
			</div>
		</Menu>
	);

	const drawer = (
		<div id="admin-dashboard" className="admin-dashboard">
			<div className={classes.toolbar} />

			{/* {mobileOpen && (
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}

					// className={classes.menuButton}
				>
					<ChevronLeftIcon />
				</IconButton>
			)} */}

			<div className="welcome-container">
				<div className="sidebar-icon-container">
					{mobileOpen && (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}

							// style={{ fontSize: "2rem" }}
							// className={classes.menuButton}
						>
							<Avatar style={{ width: 24, height: 24, backgroundColor: "#25a18e" }}>
								<ChevronLeftIcon />
							</Avatar>
							{/* <ChevronLeftIcon style={{ fontSize: "2rem" }} /> */}
						</IconButton>
					)}
				</div>
				<div className="sidebar-logo-container">
					<a href="/">
						<img src={curaFootLogoWhite} alt="" />
					</a>
				</div>

				<div className="dashboard-type-text" id="dashboard-type-text">
					<div>
						{user?.role === ROLES.super_admin
							? initCapitalize(ROLES.super_admin)
							: user?.role === ROLES.admin
							? initCapitalize(ROLES.admin)
							: initCapitalize(ROLES.doctor)}
					</div>
				</div>

				<StyledBadge
					overlap="circular"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					variant="dot"
				>
					<Avatar alt="Remy Sharp" className={classes.avatarColor}>
						<div className="avatar-text">
							{/* {user && user.first_name.charAt(0) + user.last_name.charAt(0)} */}
							SM
						</div>
					</Avatar>
				</StyledBadge>
				<div className="welcome-text" id="welcome-text" onClick={handleNameClick}>
					{user && user.first_name && user.last_name ? user.first_name + " " + user.last_name : null}
				</div>
			</div>
			<Divider />
			<List>
				{SIDEBAR_OPTIONS["SUPER ADMIN"]?.map((tab: Tab, tabIndex: number) => {
					return (
						<>
							{tab.subTabs.length > 0 ? (
								<Accordion className="list-item-accordion" key={tabIndex}>
									{
										<AccordionSummary
											expandIcon={tab.subTabs.length > 0 ? <ExpandMoreIcon /> : <div />}
											aria-controls="panel1a-content"
											id="panel1a-header"
											className="list-item-accordion-summary"
										>
											<div style={{ marginRight: 10 }}>{tab.icon()}</div>
											<div>{tab.caption}</div>
										</AccordionSummary>
									}
									<AccordionDetails>
										<List>
											{tab.subTabs.length !== 0 &&
												tab.subTabs.map((subTab, subTabIndex) => {
													const { caption, route } = subTab;
													return (
														<div key={subTabIndex}>
															{caption === "Permission Management" ? (
																user?.is_component_permission ||
																user?.is_group_and_store_permission ||
																user?.is_feature_permission ? (
																	<ListItem
																		button
																		className="list-item-container"
																		onClick={() => navigate(route)}
																		style={{
																			backgroundColor:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#85CE36"
																					: "inherit",
																			color:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#4e4e4e"
																					: "inherit",
																			fontWeight:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "bolder"
																					: "inherit"
																		}}
																	>
																		<ListItemText
																			primary={caption}
																			className="list-item-text"
																			style={{
																				color:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "#4e4e4e"
																						: "inherit",
																				fontWeight:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "bolder"
																						: "inherit"
																			}}
																		/>
																	</ListItem>
																) : null
															) : caption === "User Management" ? (
																user?.is_user_management_permission ? (
																	<ListItem
																		button
																		className="list-item-container"
																		onClick={() => navigate(route)}
																		style={{
																			backgroundColor:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#85CE36"
																					: "inherit",
																			color:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#4e4e4e"
																					: "inherit",
																			fontWeight:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "bolder"
																					: "inherit"
																		}}
																	>
																		<ListItemText
																			primary={caption}
																			className="list-item-text"
																			style={{
																				color:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "#4e4e4e"
																						: "inherit",
																				fontWeight:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "bolder"
																						: "inherit"
																			}}
																		/>
																	</ListItem>
																) : null
															) : caption === "Product Management" ? (
																user?.is_product_management_permission ? (
																	<ListItem
																		button
																		className="list-item-container"
																		onClick={() => navigate(route)}
																		style={{
																			backgroundColor:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#85CE36"
																					: "inherit",
																			color:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#4e4e4e"
																					: "inherit",
																			fontWeight:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "bolder"
																					: "inherit"
																		}}
																	>
																		<ListItemText
																			primary={caption}
																			className="list-item-text"
																			style={{
																				color:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "#4e4e4e"
																						: "inherit",
																				fontWeight:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "bolder"
																						: "inherit"
																			}}
																		/>
																	</ListItem>
																) : null
															) : caption === "Disease Management" ? (
																user?.is_disease_management_permission ? (
																	<ListItem
																		button
																		className="list-item-container"
																		onClick={() => navigate(route)}
																		style={{
																			backgroundColor:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#85CE36"
																					: "inherit",
																			color:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#4e4e4e"
																					: "inherit",
																			fontWeight:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "bolder"
																					: "inherit"
																		}}
																	>
																		<ListItemText
																			primary={caption}
																			className="list-item-text"
																			style={{
																				color:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "#4e4e4e"
																						: "inherit",
																				fontWeight:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "bolder"
																						: "inherit"
																			}}
																		/>
																	</ListItem>
																) : null
															) : caption === "Device Management" ? (
																user?.is_device_management_permission ? (
																	<ListItem
																		button
																		className="list-item-container"
																		onClick={() => navigate(route)}
																		style={{
																			backgroundColor:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#85CE36"
																					: "inherit",
																			color:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#4e4e4e"
																					: "inherit",
																			fontWeight:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "bolder"
																					: "inherit"
																		}}
																	>
																		<ListItemText
																			primary={caption}
																			className="list-item-text"
																			style={{
																				color:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "#4e4e4e"
																						: "inherit",
																				fontWeight:
																					selectedTab.subTabIndex ===
																						subTabIndex &&
																					selectedTab.index === tabIndex
																						? "bolder"
																						: "inherit"
																			}}
																		/>
																	</ListItem>
																) : null
															) : (
																<ListItem
																	button
																	className="list-item-container"
																	onClick={() => navigate(route)}
																	style={{
																		backgroundColor:
																			selectedTab.subTabIndex === subTabIndex &&
																			selectedTab.index === tabIndex
																				? "#85CE36"
																				: "inherit",
																		color:
																			selectedTab.subTabIndex === subTabIndex &&
																			selectedTab.index === tabIndex
																				? "#4e4e4e"
																				: "inherit",
																		fontWeight:
																			selectedTab.subTabIndex === subTabIndex &&
																			selectedTab.index === tabIndex
																				? "bolder"
																				: "inherit"
																	}}
																>
																	<ListItemText
																		primary={caption}
																		className="list-item-text"
																		style={{
																			color:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "#4e4e4e"
																					: "inherit",
																			fontWeight:
																				selectedTab.subTabIndex ===
																					subTabIndex &&
																				selectedTab.index === tabIndex
																					? "bolder"
																					: "inherit"
																		}}
																	/>
																</ListItem>
															)}
														</div>
													);
												})}
										</List>
									</AccordionDetails>
								</Accordion>
							) : (
								<Accordion
									className="list-item-accordion"
									key={tabIndex}
									expanded={false}
									onClick={() => navigate(tab.route)}
								>
									<AccordionSummary
										expandIcon={tab.subTabs.length > 0 ? <ExpandMoreIcon /> : <div />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										className="list-item-accordion-summary"
									>
										<div style={{ marginRight: 10 }}>{tab.icon()}</div>
										<div>{tab.caption}</div>
									</AccordionSummary>
								</Accordion>
							)}
						</>
					);
				})}
			</List>
		</div>
	);

	const container = window !== undefined ? () => window.document.body : undefined;

	return (
		<div className={classes.application + " admin-sidebar "}>
			<Suspense fallback={<div />}>
				<LazyCustomDialog dialogProps={logoutDialogProps} />
			</Suspense>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={classes.appBar}
				style={{
					// [theme.breakpoints.up("m")]: {
					width: `calc(100% - ${drawerWidth}px)`,
					marginLeft: drawerWidth,
					backgroundColor: "#EEEEEE",
					color: "black"

					// backgroundColor: "#EEEEEE",
					// color: "black"
				}}
			>
				<Toolbar className="toolbar">
					{!mobileOpen && (
						<IconButton
							color="primary"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton>
					)}
					<div className="appbar-header" id="appbar-header">
						{dashboardHeader}
					</div>

					<div className={classes.grow} />
					<div className={classes.sectionDesktop + " sec-desk"}>
						<Tooltip title="Profile" enterDelay={500} leaveDelay={200}>
							<IconButton
								edge="end"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={handleProfileMenuOpen}
								color="inherit"
							>
								<AccountCircle style={{ color: "#4e4e4e" }} />
							</IconButton>
						</Tooltip>
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="inherit"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{/* {renderGroupNameSelectionMenu} */}
			{renderMenu}
			<nav
				className={classes.drawer}
				aria-label="mailbox folders"
				style={{
					[theme.breakpoints.up("md")]: {
						width: drawerWidth,
						flexShrink: 0
					}
				}}
			>
				{/* <Hidden smUp implementation="css"> */}

				<Drawer
					container={container}
					variant="persistent"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					// classes={{
					// 	paper: classes.drawerPaper
					// }}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.,
					}}
					style={{
						width: drawerWidth,
						// backgroundColor: "#EEEEEE"
						// backgroundColor: "#3A4652"
						// backgroundColor: "#183D3D"
						backgroundColor: "#003545"
					}}
				>
					{drawer}
				</Drawer>
			</nav>
			<main className={classes.content + " main"}>
				<div className={classes.toolbar} />
				<Suspense fallback={<div />}>
					{/* <WrappedComponent /> */}
					<Outlet />
				</Suspense>
			</main>
		</div>
	);
};

export default SuperAdminSidebar;
