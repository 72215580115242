import { FC, useCallback, useState } from "react";
import { Logo } from "../../../../assets";
import { FormControl, IconButton, InputAdornment, InputLabel, Paper } from "@material-ui/core";
import ThemeTextField from "../../../shared/theme/textField/ThemeTextField";
import { AiFillEye, AiFillEyeInvisible, AiOutlineLogin } from "react-icons/ai";
import ThemeButton from "../../../shared/theme/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const ResetPassword: FC = (): JSX.Element => {
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const { jwt } = useParams();
	const navigate = useNavigate();

	const toggleShowPassword = useCallback(() => {
		setShowPassword(!showPassword);
	}, [showPassword]);

	const handlePasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	}, []);

	const handleSubmit = useCallback(async () => {
		try {
			const response = await axios.post(`http://localhost:4008/api/v1/auth/forget-password/verify-token/${jwt}`, {
				new_password: password
			});
			if (response) {
				alert("Password has been reset Sucessfully");
				navigate("/login");
			}
		} catch (err) {
			alert("Error");
		}
	}, [jwt, navigate, password]);

	return (
		<div className="forget-password auth">
			<div className="auth-container">
				<div className="logo-container">
					<img src={Logo} className="auth-img" />
				</div>
				<Paper elevation={3} className="auth-paper-container">
					<div className="auth-text theme-primary-color">Reset Password</div>
					<div className="theme-section-margin" />
					<FormControl className="fullwidth">
						<InputLabel shrink htmlFor="bootstrap-input" className="theme-input-label">
							Password
						</InputLabel>
						<ThemeTextField
							type={showPassword ? "text" : "password"}
							value={password}
							onChange={handlePasswordChange}
							endAdornment={
								showPassword ? (
									<InputAdornment
										position="end"
										style={{
											position: "absolute",
											right: 0
										}}
									>
										<IconButton
											// sstyle={{ position: "absolute" }}
											onClick={toggleShowPassword}
										>
											<AiFillEye />
										</IconButton>
									</InputAdornment>
								) : (
									<InputAdornment
										position="end"
										style={{
											position: "absolute",
											right: 0
										}}
									>
										<IconButton onClick={toggleShowPassword}>
											<AiFillEyeInvisible />
										</IconButton>
									</InputAdornment>
								)
							}
						/>
					</FormControl>
					<div className="theme-section-margin" />
					<ThemeButton onClick={handleSubmit}>
						Submit{" "}
						<span className="button-icon">
							<AiOutlineLogin />
						</span>
					</ThemeButton>
				</Paper>
			</div>
		</div>
	);
};

export default ResetPassword;
