import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import ProductGrid from "../../../superAdmin/settings/productManagement/productGrid/ProductGrid";
import { PaginationQuery, PaginationTypes } from "../../../../../@types/pagination.types";
import { CellValueChangedEvent, ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../../../../utils/api";
import { IProduct } from "../../../../../@types/product.types";
import { IStoreType } from "../../../../../@types/store.types";
import { GroupBranding } from "../../../../../@types/group.types";
import getAgGridHeight from "../../../../../utils/commonFunctions/getAgGridHeight";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AuthContext } from "../../../../../contexts";
import ROLES from "../../../../../constants/roles";
import CustomPagination from "../../../../shared/customPagination/CustomPagination1";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";

const StoreGrid = ({ groupNumber }: { groupNumber: number | null }) => {
	const { user } = useContext(AuthContext);
	const [stores, setStores] = useState<IStoreType[]>([]);
	const navigate = useNavigate();
	const [gridHeight, setGridHeight] = useState<string>("400px");

	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	// eslint-disable-next-line no-unused-vars
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);
	const [limit, setLimit] = useState<number | null>(null);
	const [pagination, setPagination] = useState<PaginationTypes>({
		currentPage: 1,
		pageCount: undefined
	});
	const { currentPage, pageCount } = pagination;

	console.log("====>group number", groupNumber);
	// const _limit = getLimit(xl, lg, md, sm, xs);
	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleViewcustomerList = ({ data }: { data: IStoreType }) => {
		const handleNavigateToCustomerList = () => {
			navigate(`/store-admin/customers?gn=${data.group_number}&sn=${data.store_number}`);
		};
		return (
			<div>
				<Button className="ag-grid-cell-button" variant="outlined" onClick={handleNavigateToCustomerList}>
					See Details
				</Button>
			</div>
		);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};
	const getPaginatedStoreList = useCallback(async () => {
		try {
			if (typeof limit === "number" && typeof pagination.currentPage === "number") {
				const paginationQuery: PaginationQuery = {
					limit: limit,
					page: pagination.currentPage
				};
				if (groupNumber) {
					const filter: any = { ...paginationQuery, group_number: groupNumber };
					const storeList = await api.stores.getStoreList(filter);
					if (storeList && storeList.groups) {
						setStores(storeList.groups);
					}
				}
			}
		} catch (error) {
			console.log("error", error);
		}
	}, [limit, pagination.currentPage, groupNumber]);

	const handlePageChange = useCallback(
		(event: React.ChangeEvent<unknown>, page: number) => {
			setPagination(
				Object.assign({}, pagination, {
					currentPage: page
				})
			);
		},
		[pagination]
	);

	useEffect(() => {
		const _gridHeight = getAgGridHeight(stores.length);
		setGridHeight(_gridHeight);
	}, [stores.length]);

	useEffect(() => {
		setLimit(5);
		getPaginatedStoreList();
	}, [getPaginatedStoreList]);

	return (
		<div>
			<div className="all-customer-page-container">
				<div className="all-customers-grid-container">
					<div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
						<AgGridReact
							ref={agGridRef}
							rowData={stores}
							onGridReady={handleAgGridReady}
							onFirstDataRendered={handleFirstDataRendered}
							rowSelection={"Single"}
							animateRows
							suppressColumnVirtualisation={true}
							defaultColDef={{
								filter: "agTextColumnFilter",
								floatingFilter: true,
								resizable: true
							}}
							enableCellChangeFlash={true}
							frameworkComponents={{
								handleViewcustomerList: handleViewcustomerList
								// handleActionDetailsButtonRender: handleActionDetailsButtonRender
							}}
						>
							{user &&
							user.role === ROLES.super_admin &&
							((user.first_name === "Sourish" && user.last_name === "Mukherjee") ||
								(user.first_name === "Anupam" && user.last_name === "Biswas") ||
								(user.first_name === "Rahul" && user.last_name === "Dutta") ||
								(user.first_name === "Suman" && user.last_name === "Dutta")) ? (
								<AgGridColumn
									field="group_number"
									headerName="Group Number"
									floatingFilterComponentParams={{ suppressFilterButton: true }}
									// suppressSizeToFit
									filter={false}
								></AgGridColumn>
							) : null}
							<AgGridColumn
								field="store_name"
								headerName="Store Name"
								// suppressSizeToFit
								// onCellClicked={handleViewcustomersDetails}
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
							></AgGridColumn>
							{user?.is_customer_name_visibility ? (
								<>
									<AgGridColumn
										field="store_number"
										headerName="Store Number"
										// suppressSizeToFit
										// onCellClicked={handleViewcustomersDetails}
										cellStyle={() => {
											return { textAlign: "left", cursor: "pointer" };
										}}
										//floatingFilterComponentParams={{ suppressFilterButton: true }}
										// floatingFilterComponentParams={{
										// 	suppressFilterButton: true
										// }}
									></AgGridColumn>
									<AgGridColumn
										field="group_number"
										headerName="Group Number"
										// suppressSizeToFit
										//floatingFilterComponentParams={{ suppressFilterButton: true }}
										// onCellClicked={handleViewcustomersDetails}
										cellStyle={() => {
											return { textAlign: "left", cursor: "pointer" };
										}}
									></AgGridColumn>
								</>
							) : null}
							<AgGridColumn
								field="contact_phone_number"
								headerName="Contact Number"
								suppressSizeToFit
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
								//floatingFilterComponentParams={{ suppressFilterButton: true }}
							></AgGridColumn>
							{user?.is_phone_number_visibility ? (
								<AgGridColumn
									field="city"
									headerName="City"
									// onCellClicked={handleViewcustomersDetails}
									cellStyle={() => {
										return { textAlign: "left", cursor: "pointer" };
									}}
								></AgGridColumn>
							) : null}
							<AgGridColumn
								field="GST_number"
								headerName="GST Number"
								cellStyle={() => {
									return { textAlign: "left", cursor: "pointer" };
								}}
							></AgGridColumn>
							<AgGridColumn
								field="GST_number"
								cellRenderer="handleViewcustomerList"
								headerName="See Customers"
							></AgGridColumn>
						</AgGridReact>
					</div>
				</div>
				<div className="custom-pagination" id="custom-pagination">
					<CustomPagination
						pageCount={pageCount}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default StoreGrid;
