import { useCallback, useEffect, useState } from "react";
import { api } from "../../api";
import { IStoreType } from "../../../@types/store.types";

const useGetStoreList = (groupNumber: number | null) => {
	const [storeList, setStoreList] = useState<{ name: string; number: number }[]>([]);
	console.log("===>group number", groupNumber);
	const getStoreList = useCallback(async () => {
		if (groupNumber) {
			const filter = { group_number: groupNumber };
			const response = await api.stores.getStoreList(filter);
			console.log("===>store respone", response.groups);
			const stores: IStoreType[] = response.groups;
			const storeListData = stores.map((store: IStoreType) => {
				return { name: store.store_name, number: store.store_number };
			});
			setStoreList(storeListData);
		} else {
			console.log("===>store respone not found");
		}
	}, [groupNumber]);

	useEffect(() => {
		getStoreList();
	}, [getStoreList]);
	return { storeList };
};

export default useGetStoreList;
