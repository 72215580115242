import { Card, CardContent, Paper } from "@material-ui/core";
import React from "react";
import QRCode from "react-qr-code";

const ReportQrCode = ({ scan_id }: { scan_id: string }) => {
	return (
		<div>
			<Paper className="qrContainer" elevation={3} style={{ padding: "1.rem" }}>
				<div className="linkQr">
					<QRCode
						style={{ height: "auto", maxWidth: "80%", marginBottom: "1rem" }}
						value={`http://admin-curafoot.s3-website.ap-south-1.amazonaws.com/reports?qs=${scan_id}`}
						// value={"https://google.com"}
						fgColor="#003545"
						viewBox={"0 0 256 256"}
					/>
					<h4>Scan this QR Code to get the Feet Scan Report</h4>
				</div>
			</Paper>
		</div>
	);
};

export default ReportQrCode;
